import { object, number } from 'yup';

export const defaultValues = {
  logistic_invoice_limit: '',
  logistic_invoice_increment: '',
};

export const schema = object().shape({
  logistic_invoice_limit: number()
    .nullable()
    .label('Current Logistic Limit'),
  logistic_invoice_increment: number()
    .nullable()
    .label('Daily Limit'),
});
