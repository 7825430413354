import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Card, Chip, Divider, Grid, InputAdornment, Stack, TextField, Typography
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import TableFilterContainer from '../../../../components/EditableTable/TableFilterContainer';
import { maxWidth800px } from '../../../../constans/mediaQuery';
import {
  boardsWrap,
  GridRoot,
  StackStyle,
  StatusHeader,
  StatusText
} from '../../../Logistics/OrdersDashboard/styles';
import { PERSONAL_DASHBOARD_CONFIG, PERSONAL_INVOICE_STATUS_OPTIONS } from '../constants';
import { CardHeaderSx } from '../styles';
import PersonalDashboardInvoiceCard from './components/PersonalDashboardInvoiceCard';
import InvoiceForm from './components/InvoiceForm';

const PersonalDashboard = ({ props }) => {
  const { filterState, setFilterState, setSearch } = props;

  const [locationInvoiceId, setLocationInvoiceId] = useState(null);

  const handleFilterState = useCallback((value, type) => {
    setFilterState(prev => ({
      ...prev,
      [type]: value
    }));
  }, []);

  const handleCloseInvoice = useCallback(() => {
    setLocationInvoiceId(null);
  }, []);

  const handleOpenInvoice = useCallback((id) => {
    setLocationInvoiceId(id);
  }, []);

  const filterConfig = {
    afterDivider: false,
    items: [
      {
        value: filterState.payment_status,
        callback: (e) => handleFilterState(e.target.value, 'payment_status'),
        closeCallback: () => handleFilterState(null, 'payment_status'),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: PERSONAL_INVOICE_STATUS_OPTIONS,
        placeholder: 'Select Status'
      },
    ]
  };

  const renderGridItems = () => PERSONAL_DASHBOARD_CONFIG.map(({
    title, statuses, bgColor, permissions
  }) => {
    const taskData = props.handlePersonalTasksByStatus(statuses);

    return (
      <Grid item xs={2.4} key={title}>
        <Card sx={{ backgroundColor: bgColor }}>
          <Box sx={StatusHeader}>
            <Box>
              <Typography noWrap variant="h6" sx={StatusText}>
                {title}
              </Typography>
            </Box>
            <Box>
              <Chip label={taskData.count} variant="outlined" />
            </Box>
          </Box>
          <Divider />
          <Stack spacing={2} sx={StackStyle}>
            {taskData.invoices?.map((invoice) => (
              <PersonalDashboardInvoiceCard
                key={invoice.id}
                props={props}
                invoice={invoice}
                onOpen={handleOpenInvoice}
                permissions={permissions}
              />
            ))}
          </Stack>
        </Card>
      </Grid>
    );
  });

  return (
    <>
      <Card sx={{ mt: 3, padding: '0 0.7rem 0.7rem 0.7rem' }}>
        <TableFilterContainer filterConfig={filterConfig} />
      </Card>
      <Card sx={CardHeaderSx}>
        <Box sx={{
          display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '12px'
        }}
        >
          <TextField
            size="small"
            autoComplete="off"
            type="text"
            sx={{
              minWidth: '300px', maxWidth: '300px'
            }}
            placeholder="Search this board"
            onChange={debounce((e) => setSearch(e.target.value), 400)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Grid container sx={GridRoot}>
          <Grid
            container
            sx={{
              ...boardsWrap,
              ...(maxWidth800px() && { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' })
            }}
            spacing={6}
          >
            {renderGridItems()}
          </Grid>
        </Grid>
        <InvoiceForm onClose={handleCloseInvoice} locationInvoiceId={locationInvoiceId} props={props} />
      </Card>
    </>
  );
};

export default PersonalDashboard;
