import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import DefaultButton from '../../../../../components/Button';
import { GreenButton } from '../../../../Logistics/style';
import { useApproveForwardInvoiceMutation } from '../../../../../store/session';

const ApproveForwardButton = ({ invoiceId, onSubmit }) => {
  const [approveInvoice, { isLoading }] = useApproveForwardInvoiceMutation();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleApprove = async () => {
    const toastId = toast.loading('Loading...');
    const { error } = await approveInvoice(invoiceId);

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    setConfirmOpen(false);
    onSubmit();
  };

  return (
    <>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        onClick={handleOpen}
      >
        Approve Forward
      </Button>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to approve forward this invoice? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={handleClose}>
            Cancel
          </DefaultButton>
          <Button
            sx={GreenButton}
            onClick={handleApprove}
            disabled={isLoading}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApproveForwardButton;
