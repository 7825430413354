import PollIcon from '@mui/icons-material/Poll';
import {
  Box, Card, CardContent, Dialog, DialogContent, Divider, Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import CardStatistics from '../../components/CardStatistics';
import { CloseIcon } from '../../../../components/Icons';
import { headerBtn } from '../../../Invoices/style';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import FormGroup from '../../../../form/components/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../../form/components/Input';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useGetLogisticSettingsQuery, useUpdateLogisticSettingsMutation } from '../../../../store/slices/financeSlice';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../../utils/locationSearch';
import Avatar from "../../../../components/Avatar";
import {TextWithIcon} from "../../../Logistics/style";
import EuroIcon from "@mui/icons-material/Euro";

const Settings = ({ changeButton = true, queryParams = {} }) => {
  const userAccess = usePermissions('logistic_statistic_setting_access');
  const [openModal, setOpenModal] = useState(false);

  const { data: dataSettings, refetch } = useGetLogisticSettingsQuery(
    stringifyLocationSearch(queryParams),
    { refetchOnMountOrArgChange: true }
  );

  const [updateSettings] = useUpdateLogisticSettingsMutation();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    setValue('logistic_invoice_limit', dataSettings?.logistic_invoice_limit);
    setValue('logistic_invoice_increment', dataSettings?.logistic_invoice_increment);
  }, [dataSettings]);

  const onClose = () => {
    setOpenModal(false);
  };

  const onSubmitForm = async (data) => {
    const toastId = toast.loading('Loading...');
    const { error } = await updateSettings({
      settings: [
        {
          key: 'logistic_invoice_limit',
          value: data.logistic_invoice_limit,
        },
        {
          key: 'logistic_invoice_increment',
          value: data.logistic_invoice_increment,
        }
      ]
    });
    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    onClose();
    refetch();
  };

  return (
    <>
      <Grid item xs={12}>
        {dataSettings && (
          <Grid container spacing={6}>
            <Grid item xs={12} md={3} sm={6}>
              <CardStatistics title="Current Logistic Limit" icon={<PollIcon />} stats={dataSettings.logistic_invoice_limit} color="primary" />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <Card>
                <CardContent sx={{ py: theme => `${theme.spacing(4)} !important` }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar skin="light" color="warning" variant="rounded">
                        <PollIcon />
                      </Avatar>
                      <Box sx={{ display: 'flex', flexDirection: 'column', pl: '8px' }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                          <Typography variant="h6" sx={TextWithIcon}>
                            <EuroIcon fontSize="small" />
                            {dataSettings.logistic_invoice_increment}
                          </Typography>
                        </Box>
                        <Typography variant="caption">Daily Limit</Typography>
                      </Box>
                    </Box>
                    {changeButton && <Button disabled={!userAccess} onClick={() => setOpenModal(true)}>Change</Button>}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog
        fullWidth
        open={openModal}
        onClose={onClose}
        maxWidth="md"
      >
        <DialogContent>
          <Card sx={{ padding: 4 }}>
            <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h5" noWrap>Change Limits</Typography>
              </Box>
              <CloseIcon
                sx={CloseIconStyle}
                onClick={onClose}
              />
            </Box>

            <Divider />
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormGroup label="Current Logistic Limit" required hasError={!!errors.logistic_invoice_limit}>
                    <Controller
                      name="logistic_invoice_limit"
                      control={control}
                      render={({ field }) => (
                        <Input type="number" placeholder="Current Logistic Limit" {...field} />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup label="Daily Limit" required hasError={!!errors.logistic_invoice_increment}>
                    <Controller
                      name="logistic_invoice_increment"
                      control={control}
                      render={({ field }) => (
                        <Input type="number" placeholder="Daily Limit" {...field} />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Button type="submit" title="Change" />
            </form>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Settings;
