import { yupResolver } from '@hookform/resolvers/yup';
import {
  Divider, Grid, InputAdornment, TextField
} from '@mui/material';
import { Counter } from 'mdi-material-ui';
import React, {useCallback, useEffect} from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import Drawer from '../../../../../components/Drawer';
import DatePicker from '../../../../../form/components/DatePicker';
import FormGroup from '../../../../../form/components/FormGroup';
import { useAppDispatch } from '../../../../../hooks/toolkitHooks';
import { useCreateTakarekTransactionsMutation } from '../../../../../store/slices/financeSlice';
import { fetchCurrencies } from '../../../../../store/slices/logisticsSlice';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import { GreenButton } from '../../../../Logistics/style';
import { defaultValues, schema } from './schema';
import Input from '../../../../../form/components/Input';
import FieldError from '../../../../../form/components/FieldError';

const AddManualTransactions = (props) => {
  const {
    open,
    onClose,
    refresh,
    currentAccount
  } = props;
  const dispatch = useAppDispatch();
  const { currencies } = useSelector((state) => state.logistics);
  const [createTransaction, { isLoading }] = useCreateTakarekTransactionsMutation();

  const {
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: false,
  });

  const onSubmit = useCallback( async (data) => {
    const toastId = toast.loading('Loading...');
    const sendData = {
      ...data,
      currency: currencies.length ? currencies?.find(item => item?.value === data?.currency)?.label : undefined
    };
    const { error } = await createTransaction(sendData);

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    onClose();
    refresh();
    reset(defaultValues);

    toast.success('Successfully!', {
      id: toastId
    });
  }, [currencies]);

  useEffect(() => {
    if (!currencies) {
      dispatch(fetchCurrencies());
    }
  }, [currencies]);

  useEffect(() => {
    if (open) {
      setValue('bank_name', 'TAKAREK Pannonia');
      if (currentAccount) setValue('account_number_id', +currentAccount);
    }
  }, [open, currentAccount]);

  return (
    <Drawer
      title="Add New Transactions To TAKAREK Pannonia Bank"
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ padding: '0 24px' }}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <FormGroup label="Amount" required hasError={!!errors.amount}>
              <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    placeholder="Amount"
                    size="small"
                    error={!!errors.amount}
                    sx={{ width: '100%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Counter sx={{ ...(field?.value && { color: '#626477' }) }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup label="Value Date" required hasError={!!errors.value_date}>
              <Controller
                control={control}
                name="value_date"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'value_date', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup label="Name of Address" hasError={!!errors.name_of_address}>
              <Controller
                name="name_of_address"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Name of Address"
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.name_of_address} />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup label="Note" hasError={!!errors.note}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Note"
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.note} />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup label="Details" hasError={!!errors.details}>
              <Controller
                name="details"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Details"
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.details} />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup label="Beneficiary account number" hasError={!!errors.beneficiary_account_number}>
              <Controller
                name="beneficiary_account_number"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Beneficiary account number"
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.beneficiary_account_number} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{
                mb: 4, ...(GreenButton), maxWidth: '220px', alignSelf: 'center'
              }}
              disabled={isLoading}
              type="submit"
            >
              Add Transaction
            </Button>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default AddManualTransactions;
