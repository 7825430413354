import {
  object, string
} from 'yup';

export const defaultValues = {
  closed_by: '',
  due_date: '',
  ods: '',
};

export const schema = object().shape({
  name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
  designation: string()
    .required('${label} cannot be blank.')
    .label('Designation'),
  ods: string()
    .nullable()
    .label('ODS'),
  wo_date: string(),
  state: string(),
  closed_by: string()
    .label('Closed by')
    .when(['state', 'ods'], {
      is: (state, ods) => (state?.toString() === '5' || (+ods !== 0)),
      then: (fieldSchema) => fieldSchema.required(),
    }),
  due_date: string()
    .label('WO Closed Date')
    .when('state', {
      is: (val) => val?.toString() === '5',
      then: (fieldSchema) => fieldSchema.required(),
    })
    .nullable(),
  due_fh: string()
    .label('Due FH')
    .matches(
      /^\d{1,5}:[0-5][0-9]$/,
      'Incorrect time format.'
    )
    .nullable()
});
