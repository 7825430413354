import React from 'react';
import AdminLayout from '../layouts/AdminLayout';
import Layout from '../layouts/Layout';
import LoginPage from '../pages/Login/Login';
import MainDeck from '../pages/MainDeck/MainDeck';
import MntAircraft from '../pages/Maintenance/Aircraft/Form';
import MntAircraftTable from '../pages/Maintenance/Aircraft/Table';

// Quotations
import Quotation from '../pages/Quotations/CreateQuotation/CreateQuotation';
import Quotations from '../pages/Quotations/Quotations';

import AircraftProfiles from '../pages/AircraftProfiles/AircraftProfiles';
import AircraftProfileForm from '../pages/AircraftProfiles/form/AircraftProfileForm';
import AircraftTypes from '../pages/AircraftTypes/AircraftTypes';
import AircraftTypeForm from '../pages/AircraftTypes/form/AircraftTypeForm';
import Flights from '../pages/Flights/Flights';
import FlightForm from '../pages/Flights/form/FlightForm';
import FuelPayments from '../pages/FuelPayments/FuelPayments';
import FuelPaymentForm from '../pages/FuelPayments/form/FuelPaymentForm';
import FuelPrice from '../pages/FuelPrice/FuelPrice';
import FuelPriceForm from '../pages/FuelPrice/form/FuelPricetForm';
import FuelUplift from '../pages/FuelUplift/FuelUplift';
import MainDeckSchedule from '../pages/MainDeckSchedule/MainDeckSchedule';

// Rosters
import AtrCrewRoster from '../pages/AtrCrewRoster/AtrCrewRoster';
import DutyFlightTime from '../pages/DutyFlightTime/DutyFlightTime';
import OpsCrewRoster from '../pages/OpsCrewRoster/OpsCrewRoster';
import SabCrewRoster from '../pages/SabCrewRoster/SabCrewRoster';
import TechniciansCrewRoster from '../pages/TechniciansCrewRoster/TechniciansCrewRoster';

import Airport from '../pages/Airport/Airport';
import AirportForm from '../pages/Airport/CreateAirport/AirportForm';
import Caa from '../pages/Caa/Caa';
import CaaForm from '../pages/Caa/CreateCaa/CaaForm';
import Company from '../pages/Company/Company';
import CompanyForm from '../pages/Company/CreateCompany/CompanyForm';
import AddCrew from '../pages/Crew/AddCrew/AddCrew';
import Crew from '../pages/Crew/Crew';
import FlightTimeLimitations from '../pages/Crew/FlightTimeLimitations';
import LogBook from '../pages/Crew/LogBook';
import CrewDoc from '../pages/CrewDoc/CrewDoc';
import CrewForm from '../pages/CrewForm/CrewForm';
import DocumentsAndInvoices from '../pages/DocumentsAndInvoices';
import CrewBriefing from '../pages/Flights/CrewBriefing';
import FuelBalance from '../pages/FuelBalance/FuelBalance';
import AtrMaintenanceForm from '../pages/Maintenance/MaintenanceACFT/AtrMaintenanceForm';
import MaintenanceACFT from '../pages/Maintenance/MaintenanceACFT/MaintenanceACFT';
import CreateWorkOrder from '../pages/Maintenance/WorkOrder/CreateWorkOrder';
import WorkOrder from '../pages/Maintenance/WorkOrder/WorkOrder';
import WorkOrderTaskForm from '../pages/Maintenance/WorkOrder/WorkOrderTaskForm';
import MyProfile from '../pages/MyProfile/MyProfile';
import OdsAction from '../pages/Ods/OdsAction/OdsAction';
import OdsForm from '../pages/Ods/OdsForm';
import Ods from '../pages/Ods/OdsPanel';
import OdsPdf from '../pages/Ods/OdsPdf';
import OdsPositionForm from '../pages/Ods/OdsPositionForm';
import OdsTable from '../pages/Ods/OdsTable';
import OdsTrainingForm from '../pages/Ods/OdsTrainingForm';
import OperatorForm from '../pages/Operators/CreateOperator/OperatorForm';
import Operators from '../pages/Operators/Operators';
import SupplierForm from '../pages/Supplier/CreateSupplier/SupplierForm';
import Supplier from '../pages/Supplier/Supplier';
// TODO: need in the future
// import MaintenanceArchive from '../pages/Maintenance/MaintenanceArchive/MaintenanceArchive';

// TODO: at least group by comments
// Directories

// TrainingModule
import CreateDocumentsBlock from '../pages/TrainingModule/FlightCrew/DefaultDocuments/CreateDocumentsBlock';
import DefaultDocuments from '../pages/TrainingModule/FlightCrew/DefaultDocuments/DefaultDocuments';
import FlightCrew from '../pages/TrainingModule/FlightCrew/FlightCrew';
import PilotPage from '../pages/TrainingModule/FlightCrew/PilotPage/PilotPage';
import TrainingModule from '../pages/TrainingModule/MainPage/TrainingModule';
import InstructorPage from '../pages/TrainingModule/TrainingPersonnel/InstructorPage/InstructorPage';
import TrainingPersonnel from '../pages/TrainingModule/TrainingPersonnel/TrainingPersonnel';
import TrainingBlocks from '../pages/TrainingModule/TrainingProgram/ManageTrainingBlocks/TrainingBlocks';
import TrainingProgram from '../pages/TrainingModule/TrainingProgram/TrainingProgram';
import { TrainingReport } from '../pages/TrainingModule/TrainingReport';
import { TrainingReportForm } from '../pages/TrainingModule/TrainingReport/TrainingReportForm';
import TrainingSchedule from '../pages/TrainingModule/TrainingSchedule/TrainingSchedule';

// User Management
import { Navigate } from 'react-router-dom';
import UsersGroups from '../pages/UsersSection/AccessGroups';
import UserGroupForm from '../pages/UsersSection/AccessGroups/UserGroupForm';
import Users from '../pages/UsersSection/Users';
import UserChangePassword from '../pages/UsersSection/Users/UserChangePassword';
import UserForm from '../pages/UsersSection/Users/UserForm';
import UsersPositions from '../pages/UsersSection/UsersPositions';
import UserFormPositions from '../pages/UsersSection/UsersPositions/UserPositionsForm';

// Statistics
import { Banks } from '../pages/Banks/Banks';
import CreateBank from '../pages/Banks/CreateBank';
import ByCompany from '../pages/Statistics/ByCompany';
import ByDeparture from '../pages/Statistics/ByDeparture';
import ByDestination from '../pages/Statistics/ByDestination';
import ByProfit from '../pages/Statistics/ByProfit';
import BySector from '../pages/Statistics/BySector';

// Invoices
import { DeleteConfirmation } from '../pages/DeleteConfirmation/DeleteConfirmation';
import { PaymentPurposes } from '../pages/DocumentsAndInvoices/Invoices/PaymentPurposes/PaymentPurposes';
import CreateInvoice from '../pages/Invoices/CreateInvoice';
import Currency from '../pages/Invoices/Currency';
import Department from '../pages/Invoices/Department';
import { Invoices } from '../pages/Invoices/Invoices';
import DeleteConfirmationWorkOrder from '../pages/Maintenance/WorkOrder/DeleteConfitamtionWorkOrder/DeleteConfirmationWorkOrder';
import FlightMap from '../pages/Map';

// Daili Availability
import DailyAvailability from '../pages/DailyAvailability/DailyAvailability';
import Mails from '../pages/DailyAvailability/Mails/Mails';

// Logistics
import GenerateInvoice from '../pages/Finance/GenerateInvoice/Index';
import IncomePage from '../pages/Finance/Income/Index';
import MyOrders from '../pages/Logistics/MyOrders';
import OrderPage from '../pages/Logistics/MyOrders/OrderPage/OrderPage';
import OrdersDashboard from '../pages/Logistics/OrdersDashboard';
import OrderStorage from '../pages/Logistics/Storage';
import PartPage from '../pages/Logistics/Storage/PartPage/PartPage';
import Suppliers from '../pages/Logistics/Suppliers';
import SupplierPage from '../pages/Logistics/Suppliers/SupplierPage';
import PaymentData from '../pages/PaymentData/PaymentData';

// Finance
import InvoiceManagement from '../pages/Finance/InvoiceManagement/InvoiceManagement';
import { ALL_INVOICE_PERMISSIONS } from '../pages/Finance/InvoiceManagement/permissions';
import LogisticStatistics from '../pages/Finance/LogisticStatistics/LogisticStatistics';
import Statistics from '../pages/Finance/Statistics/Statistics';
import Transactions from '../pages/Finance/Transactions';
import {
  dashboardExternalAccess,
  dashboardInternalAccess,
  myOrdersExternalAccess, myOrdersInternalAccess,
  storageAccess
} from '../pages/Logistics/constants';
import AircraftBody from '../pages/Maintenance/AicraftBody/AircraftBody';
import UtilizationReports from '../pages/Maintenance/UtilizationReport/UtilizationReport';
// eslint-disable-next-line import/no-named-as-default
import SecondTrainingReportForm from '../pages/TrainingModule/TrainingReport/SecondTrainingReportForm';
import Planning from '../pages/Planning';
import PersonalReports from '../pages/Finance/PersonalReports';
import PilotReports from '../pages/Finance/PersonalReports/PilotReports';
import ReportForm from '../pages/Finance/PersonalReports/ReportForm';

export const authRoutes = {
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/flights/crew-briefing/:id',
      element: <CrewBriefing />,
    },
  ],
};

export const mainRoutes = {
  path: '/',
  element: <AdminLayout />,
  exact: false,
  children: [
    {
      path: '*',
      element: <Navigate to="/my-profile" />,
    },
    {
      path: '/',
      element: <MainDeck />,
    },
    {
      path: '/flights',
      element: <Flights />,
      permissions: 'flights_access'
    },
    {
      path: '/flights/:id',
      element: <FlightForm />,
      permissions: 'flights_access'
    },
    {
      path: '/quotations',
      element: <Quotations />,
      permissions: 'fleet_access'
    },
    {
      path: '/quotations/create',
      element: <Quotation />,
      permissions: 'fleet_access'
    },
    {
      path: '/quotations/update/:id',
      element: <Quotation />,
      permissions: 'fleet_access'
    },
    {
      path: '/aircraft/profiles',
      element: <AircraftProfiles />,
      permissions: 'aircraft_profile'
    },
    {
      path: '/aircraft/profiles/:id',
      element: <AircraftProfileForm />,
      permissions: 'aircraft_profile'
    },
    {
      path: '/aircraft/types',
      element: <AircraftTypes />,
      permissions: 'aircrafts_access'
    },
    {
      path: '/aircraft/types/:id',
      element: <AircraftTypeForm />,
      permissions: 'aircrafts_access'
    },
    {
      path: '/fuel/payments',
      element: <FuelPayments />,
      permissions: 'fuel_access',
    },
    {
      path: '/fuel/price',
      element: <FuelPrice />,
      permissions: 'fuel_access',
    },
    {
      path: '/fuel/uplift',
      element: <FuelUplift />,
      permissions: 'fuel_access',
    },
    {
      path: '/directories/operator',
      element: <Operators />,
      permissions: 'operator_access',
    },
    {
      path: '/directories/operator/:id',
      element: <OperatorForm />,
      permissions: 'operator_access',
    },
    {
      path: '/directories/airport',
      element: <Airport />,
      permissions: 'airport_access',
    },
    {
      path: '/directories/airport/:id',
      element: <AirportForm />,
      permissions: 'airport_access',
    },
    {
      path: '/directories/supplier',
      element: <Supplier />,
    },
    {
      path: '/directories/supplier/:id',
      element: <SupplierForm />,
    },
    {
      path: '/directories/company',
      element: <Company />,
      permissions: 'company_access'
    },
    {
      path: '/directories/company/:id',
      element: <CompanyForm />,
      permissions: 'company_access'
    },
    {
      path: '/directories/caa',
      element: <Caa />,
      permissions: 'caa_access'
    },
    {
      path: '/directories/caa/:id',
      element: <CaaForm />,
      permissions: 'caa_access'
    },
    {
      path: '/directories/map',
      element: <FlightMap />,
      permissions: 'map_access'
    },
    {
      path: '/training-module',
      element: <TrainingModule />,
      permissions: 'training_module_read'
    },
    {
      path: '/training-module/training-programs',
      element: <TrainingProgram />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/training-personnel',
      element: <TrainingPersonnel />,
      permissions: 'training_module_read'
    },
    {
      path: '/training-module/training-personnel/:id',
      element: <InstructorPage />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/training-schedule',
      element: <TrainingSchedule />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/flight-crew',
      element: <FlightCrew />,
      permissions: 'training_module_read'
    },
    {
      path: '/training-module/flight-crew/default-documents',
      element: <DefaultDocuments />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/flight-crew/create-block',
      element: <CreateDocumentsBlock />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/flight-crew/update-block/:id',
      element: <CreateDocumentsBlock />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/flight-crew/:id',
      element: <PilotPage />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/training-programs/blocks',
      element: <TrainingBlocks />,
      permissions: ['training_module_read', 'training_module_write']
    },
    {
      path: '/training-module/training-reports',
      element: <TrainingReport />,
      permissions: ['training_report_read', 'training_report_write']
    },
    {
      path: '/training-module/training-reports/first_report/:id',
      element: <TrainingReportForm />,
      permissions: ['training_report_write']
    },
    {
      path: '/training-module/training-reports/second_report/:id',
      element: <SecondTrainingReportForm />,
      permissions: ['training_report_write']
    },
    {
      path: '/fuel/payments/:id',
      element: <FuelPaymentForm />,
    },
    {
      path: '/fuel/balance',
      element: <FuelBalance />,
    },
    {
      path: '/fuel/price/:id',
      element: <FuelPriceForm />,
    },
    {
      path: '/maindeck/schedule',
      element: <MainDeckSchedule />,
      permissions: 'flights_schedule_access',
    },
    {
      path: '/directories/crew',
      element: <Crew />,
      permissions: 'crew_access',
    },
    {
      path: '/directories/ods/:id',
      permissions: 'ods_access',
      element: <OdsTable />,
    },
    {
      path: '/directories/ods/update/:id',
      permissions: 'ods_access',
      element: <OdsForm />,
    },
    {
      path: '/directories/crew/create',
      element: <AddCrew />,
      permissions: 'crew_access',
    },
    {
      path: '/directories/payment-data',
      element: <PaymentData />,
      permissions: ['payment_data_export', 'payment_data_admin', 'payment_data_access'],
    },
    {
      path: '/roster/saab',
      permissions: 'roster_saab_access',
      element: <SabCrewRoster />,
    },
    {
      path: '/roster/ops',
      permissions: 'roster_ops_access',
      element: <OpsCrewRoster />,
    },
    {
      path: '/roster/technicians',
      permissions: 'roster_tech_access',
      element: <TechniciansCrewRoster />,
    },
    {
      path: '/roster/atr',
      permissions: 'roster_atr_access',
      element: <AtrCrewRoster />,
    },
    {
      path: '/roster/flighttime',
      element: <DutyFlightTime />,
    },
    {
      path: '/directories/crew',
      element: <Crew />,
      permissions: 'crew_access',
    },
    {
      path: '/directories/crew/update/:id',
      element: <CrewForm />,
      permissions: 'crew_access',
    },
    {
      path: '/directories/crewdoc',
      element: <CrewDoc />,
      permissions: 'crew_access',
    },
    {
      path: '/directories/ods-action',
      permissions: 'ods_access',
      element: <OdsAction />,
    },
    {
      path: '/directories/ods-pdf/:id',
      permissions: 'ods_access',
      element: <OdsPdf />,
    },
    {
      path: '/finance/banks',
      element: <Banks />,
      permissions: 'bank_access',
    },
    {
      path: '/finance/personal-reports',
      element: <PersonalReports />,
      permissions: 'invoice_request_payment',
    },
    {
      path: '/finance/personal-reports/pilot/:id',
      element: <PilotReports />,
      permissions: 'invoice_request_payment',
    },
    {
      path: '/finance/personal-reports/pilot/report/:crew_id/:report_id',
      element: <ReportForm />,
      permissions: 'invoice_request_payment',
    },
    {
      path: '/finance/banks/create',
      element: <CreateBank />,
      permissions: 'bank_access',
    },
    {
      path: '/finance/banks/update/:id',
      element: <CreateBank />,
      permissions: 'bank_access',
    },
    {
      path: '/directories/invoices',
      element: <Invoices />,
      permissions: 'invoice_access',
    },
    {
      path: '/directories/invoices/create',
      element: <CreateInvoice />,
      permissions: ['invoice_access', 'invoice_edit', 'invoice_su'],
    },
    {
      path: '/directories/invoices/update/:id',
      element: <CreateInvoice />,
      permissions: ['invoice_access', 'invoice_edit', 'invoice_su'],
    },
    {
      path: '/directories/delete/confirmation',
      element: <DeleteConfirmation />,
      permissions: 'invoice_su',
    },
    {
      path: '/directories/invoices/department',
      element: <Department />,
      permissions: ['invoice_access', 'invoice_edit', 'invoice_su'],
    },
    {
      path: '/directories/invoices/currency',
      element: <Currency />,
      permissions: ['invoice_access', 'invoice_edit', 'invoice_su'],
    },
    {
      path: '/crew/ftl/:isPdf/:id',
      element: <FlightTimeLimitations />,
      permissions: 'crew_access',
    },
    {
      path: '/crew/ftl/:id',
      element: <FlightTimeLimitations />,
      permissions: 'crew_access',
    },
    {
      path: '/crew/log-book/:isPdf/:id',
      element: <LogBook />,
      permissions: 'crew_access',
    },
    {
      path: '/crew/log-book/:id',
      element: <LogBook />,
      permissions: 'crew_access',
    },
    {
      path: '/directories/ods/position',
      permissions: 'ods_access',
      element: <OdsPositionForm />,
    },
    {
      path: '/directories/ods/training',
      permissions: 'ods_access',
      element: <OdsTrainingForm />,
    },
    {
      path: '/directories/users-documents-and-invoices/:page?',
      permissions: ['invoice_request_access', 'users_documents'],
      element: <DocumentsAndInvoices />,
    },
    {
      path: '/directories/payment-purposes',
      permissions: 'invoice_request_approve',
      element: <PaymentPurposes />,
    },
    {
      path: '/my-profile/:page?',
      element: <MyProfile />,
    },
    {
      path: '/maintenance/:version/aircraft/:id',
      element: <MaintenanceACFT />,
      permissions: 'maintenance_atr_access',
    },
    // TODO: Need in the future
    // {
    //   path: '/maintenance/archive/:id',
    //   element: <MaintenanceArchive />,
    //   permissions: 'maintenance_access',
    // },
    {
      path: '/maintenance/:version/create/:id',
      element: <AtrMaintenanceForm />,
      permissions: 'maintenance_atr_access',
    },
    {
      path: '/maintenance/update/:id',
      element: <AtrMaintenanceForm />,
      permissions: 'maintenance_atr_access',
    },
    {
      path: '/maintenance-aircraft',
      element: <MntAircraftTable />,
      permissions: ['aircrafts_access', 'atr_maintenance_delete_access'],
    },
    {
      path: '/maintenance-aircraft/update/:id',
      element: <MntAircraft />,
      permissions: 'aircrafts_access',
    },
    {
      path: '/maintenance-utilization-report',
      element: <UtilizationReports />,
      permissions: 'utilization_report',
    },
    {
      path: '/manage-users',
      element: <Users />,
      permissions: 'users_access',
    },
    {
      path: '/manage-users-groups',
      element: <UsersGroups />,
    },
    {
      path: '/manage-users-groups/:id',
      element: <UserGroupForm />,
    },
    {
      path: '/manage-users-positions',
      element: <UsersPositions />,
    },
    {
      path: '/manage-users/:id',
      element: <UserForm />,
      permissions: 'users_access',
    },
    {
      path: '/manage-users-positions/create',
      element: <UserFormPositions />
    },
    {
      path: '/manage-users-positions/:id',
      element: <UserFormPositions />
    },
    {
      path: '/manage-users/change-password/:id',
      element: <UserChangePassword />
    },
    {
      path: '/maintenance/work-order/:id',
      permissions: 'wo_access',
      element: <WorkOrder />
    },
    {
      path: '/maintenance/work-order/update/:id',
      permissions: 'wo_access',
      element: <CreateWorkOrder />
    },
    {
      path: '/maintenance/work-order/delete/confirmation/:id',
      element: <DeleteConfirmationWorkOrder />,
      permissions: 'wo_access',
    },
    {
      path: '/ods',
      permissions: 'ods_access',
      element: <Ods />,
    },
    {
      path: '/maintenance/work-order-task/update/:id',
      permissions: 'wo_access',
      element: <WorkOrderTaskForm />
    },
    {
      permissions: ['wo_creator', 'wo_superuser', 'admin', 'aircraft_body_return_to_storage'],
      path: '/maintenance-aircraft-body',
      element: <AircraftBody />
    },
    {
      path: '/statistics/by-company',
      permissions: 'flights_comm_statistics_access',
      element: <ByCompany />
    },
    {
      path: '/statistics/by-departure',
      permissions: 'flights_statistics_access',
      element: <ByDeparture />
    },
    {
      path: '/statistics/by-destination',
      permissions: 'flights_statistics_access',
      element: <ByDestination />
    },
    {
      path: '/statistics/by-sector',
      permissions: 'flights_statistics_access',
      element: <BySector />
    },
    {
      path: '/statistics/by-profit',
      permissions: 'flights_comm_statistics_access',
      element: <ByProfit />
    },
    {
      path: '/daily_availability',
      permissions: 'daily_availability_access',
      element: <DailyAvailability />
    },
    {
      path: '/daily_availability/mails',
      permissions: 'daily_availability_access',
      element: <Mails />,
    },
    // Logistics
    {
      path: '/logistics/my-orders/orders',
      permissions: [...myOrdersExternalAccess, ...myOrdersInternalAccess],
      element: <MyOrders />,
    },
    {
      path: '/logistics/my-orders/orders/create/:id',
      permissions: myOrdersExternalAccess,
      element: <MyOrders />,
    },
    {
      path: '/logistics/my-orders/delivery',
      permissions: myOrdersInternalAccess,
      element: <MyOrders />,
    },
    {
      path: '/logistics/my-orders/orders/order-info/:id',
      permissions: myOrdersExternalAccess,
      element: <MyOrders />,
    },
    {
      path: '/logistics/my-orders/delivery/order-info/:id',
      permissions: myOrdersInternalAccess,
      element: <MyOrders />,
    },
    {
      path: '/logistics/my-orders/orders/update/:id',
      permissions: ['logistics_order_creator'],
      element: <OrderPage />,
    },
    {
      path: '/logistics/my-orders/delivery/update/:id',
      permissions: ['internal_logistics_order_creator'],
      element: <OrderPage />,
    },
    {
      path: '/logistics/dashboard/orders',
      permissions: [...dashboardExternalAccess, ...dashboardInternalAccess],
      element: <OrdersDashboard />,
    },
    {
      path: '/logistics/dashboard/delivery',
      permissions: dashboardInternalAccess,
      element: <OrdersDashboard />,
    },
    {
      path: '/logistics/orders-storage',
      permissions: storageAccess,
      element: <OrderStorage />,
    },
    {
      path: '/logistics/orders-storage/:id',
      permissions: ['logist', 'logistics_storage_master', 'logistics_order_creator', 'logistics_storage_read', 'internal_logistics_storage_master', 'logistics_storage_write', 'internal_logistics_storage_write'],
      element: <PartPage />,
    },
    {
      path: '/logistics/vendors',
      permissions: ['logistics_suppliers_read', 'logistics_suppliers_write'],
      element: <Suppliers />,
    },
    {
      path: '/logistics/vendors/:id',
      permissions: ['logistics_suppliers_read', 'logistics_suppliers_write'],
      element: <SupplierPage />,
    },
    {
      path: '/finance/income',
      permissions: ['finance_income_reader', 'finance_income_writer'],
      element: <IncomePage />
    },
    {
      path: '/finance/generate_invoice',
      permissions: ['finance_generate_invoice_read', 'finance_generate_invoice_write'],
      element: <GenerateInvoice />
    },
    {
      path: '/finance/transactions',
      permissions: ['finance_transactions_read', 'finance_transactions_write', 'finance_transactions_financiers', 'finance_transactions_tax'],
      element: <Transactions />
    },
    {
      permissions: 'finance_statistics_read',
      path: '/finance/statistics',
      element: <Statistics />
    },
    {
      permissions: ALL_INVOICE_PERMISSIONS,
      path: '/finance/invoice_management',
      element: <InvoiceManagement />
    },
    {
      path: '/finance/logistic-statistics',
      permissions: ['logistic_statistic_access'],
      element: <LogisticStatistics />
    },
    {
      path: '/directories/it-planning',
      permissions: ['plannings_read', 'plannings_write'],
      element: <Planning />
    }
  ],
};
