import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema, defaultValues } from './schema';
import {
  useGetOnePlanningTaskQuery,
  useUpdatePlanningTaskMutation,
  useCompletePlanningTaskMutation,
  useStorePlanningTaskMutation,
  useDestroyPlanningTaskMutation
} from '../../../../store/slices/planningSlice';
import { validateDatePickerValue } from '../../../../utils/setDateValue';

export const usePlanningForm = (taskID, open, onError, onSuccess, handleClose) => {
  const { data: taskData, isLoading, isFetching } = useGetOnePlanningTaskQuery(taskID, {
    refetchOnMountOrArgChange: true,
    skip: !taskID
  });

  const update = !!taskID;
  const taskLoading = isLoading || isFetching;

  const [updateTask] = useUpdatePlanningTaskMutation();
  const [completeTask] = useCompletePlanningTaskMutation();
  const [storeTask] = useStorePlanningTaskMutation();
  const [destroyTask] = useDestroyPlanningTaskMutation();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    if (taskData) {
      Object.entries(taskData).forEach(([name, value]) => {
        form.setValue(name, value ?? false);
      });
    }
  }, [taskData, taskLoading, open]);

  const onSubmit = async (data) => {
    if (!validateDatePickerValue(data?.implementation_date)) data.implementation_date = null;
    if (!validateDatePickerValue(data?.planning_date)) data.planning_date = null;
    if (!validateDatePickerValue(data?.completed_at)) data.completed_at = null;

    if (update) {
      const res = await updateTask({ id: data.id, data });
      if (res?.error) {
        onError(res?.error?.message || 'Something went wrong.');

        return;
      }
    } else {
      const res = await storeTask({ data });
      if (res?.error) {
        onError(res?.error?.message || 'Something went wrong.');

        return;
      }
    }

    onSuccess();
  };

  const handleCompleteTask = async (id) => {
    if (!id) return;

    await completeTask({ id });
    handleClose();
  };

  const handleDestroyTask = async (id) => {
    if (!id) return;

    await destroyTask({ id });
    handleClose();
  };

  return {
    form,
    onSubmit,
    taskLoading,
    taskData,
    handleCompleteTask,
    handleDestroyTask,
    update
  };
};
