import { createSlice } from '@reduxjs/toolkit';
import { sessionApi } from '../session';
import { useProvideTags } from '../../utils/hooks/useProvideTags';

export const planningSlice = createSlice({
  name: 'planning_slice',
  initialState: {},
});

sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPlanningTasks: builder.query({
      query: () => '/plannings',
      providesTags: (result, error) => useProvideTags(result, error, ['GET_ALL_PLANNING_TASKS']),
    }),
    getOnePlanningTask: builder.query({
      query: (id) => `/plannings/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_ONE_PLANNING_TASK']),
    }),
    updatePlanningTask: builder.mutation({
      query: ({ id, data }) => ({
        url: `/plannings/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_ONE_PLANNING_TASK', 'GET_ALL_PLANNING_TASKS']),
    }),
    completePlanningTask: builder.mutation({
      query: ({ id }) => ({
        url: `/plannings/complete/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_ONE_PLANNING_TASK', 'GET_ALL_PLANNING_TASKS']),
    }),
    storePlanningTask: builder.mutation({
      query: ({ data }) => ({
        url: '/plannings',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_ALL_PLANNING_TASKS']),
    }),
    destroyPlanningTask: builder.mutation({
      query: ({ id }) => ({
        url: `/plannings/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_ALL_PLANNING_TASKS']),
    }),
  }),
});

export const {
  useGetAllPlanningTasksQuery,
  useGetOnePlanningTaskQuery,
  useUpdatePlanningTaskMutation,
  useCompletePlanningTaskMutation,
  useStorePlanningTaskMutation,
  useDestroyPlanningTaskMutation
} = sessionApi;

export default planningSlice.reducer;
