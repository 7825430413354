import {
  number,
  object, string
} from 'yup';

export const defaultValues = {
  part_off_text: '',
  part_on_text: '',
  part_off_name: '',
  performed_by: 0,
  part_id: 0,
  serial_on_number: '',
  part_aircraft_body_id: 0,
  part_on_number: '',
  part_off_number: '',
  serial_off_number: '',
  part_name: '',
  storage: '',
  part_type: '',
  wo_task_id: 0,
  quantity: 1,
  unit_id: '3',
  tabValue: 'autocomplete',
  tabOffValue: 'autocomplete',
  certificate: null
};

export const schema = object().shape({
  part_type: string(),
  // Part Off
  part_off_text: string()
    .when('part_type', {
      is: (vlv) => (vlv !== 'consumable'),
      then: string().required('${label} cannot be blank.'),
      otherwise: string().nullable(),
    }).label('Part off text'),
  part_off_number: string().when('part_type', {
    is: (vlv) => (vlv !== 'consumable'),
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).max(255, '${label} max length 255').label('Part Off Number'),
  serial_off_number: string().when('part_type', {
    is: (vlv) => (vlv !== 'consumable'),
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).max(255, '${label} max length 255').label('Serial Off Number'),
  part_off_name: string().when('part_type', {
    is: (vlv) => (vlv !== 'consumable'),
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).max(255, '${label} max length 255').label('Part Off Name'),
  part_aircraft_body_id: number().when(['part_type', 'tabOffValue'], {
    is: ([part_type, tabOffValue]) => ((tabOffValue === 'autocomplete') && (part_type !== 'consumable')),
    then: number().min(1).required('${label} cannot be blank.'),
    otherwise: number().nullable(),
  }).label('Part Aircraft Body ID'),
  
  // Part On
  part_on_text: string().when(['part_id', 'tabValue'], {
    is: (part_id, tabValue) => (!!part_id || (tabValue === 'manual')),
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Part on text'),
  part_id: number().nullable().label('Part ID'),
  serial_on_number: string().max(255, '${label} max length 255').nullable().label('Serial On Number'),
  part_on_number: string().when(['part_id', 'tabValue'], {
    is: (part_id, tabValue) => (!!part_id || (tabValue === 'manual')),
    then: string().max(255, '${label} max length 255').required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Part On Number'),
  part_name: string().when(['part_id', 'tabValue'], {
    is: (part_id, tabValue) => (!!part_id || (tabValue === 'manual')),
    then: string().max(255, '${label} max length 255').required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Part Name'),
  storage: string().when(['part_id', 'tabValue'], {
    is: (part_id, tabValue) => (!!part_id || (tabValue === 'manual')),
    then: string().max(255, '${label} max length 255').required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Storage'),
  quantity: number().when(['part_id', 'tabValue'], {
    is: (part_id, tabValue) => (!!part_id || (tabValue === 'manual')),
    then: number().min(1, 'Min 1').required('${label} cannot be blank.'),
    otherwise: number().nullable(),
  }).label('WO Task ID'),
  unit_id: string().when(['part_id', 'tabValue'], {
    is: (part_id, tabValue) => (!!part_id || (tabValue === 'manual')),
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Unit'),

  wo_task_id: number().required('${label} cannot be blank.').label('WO Task ID'),
  performed_by: number().min(1, '${label} cannot be blank.').required('${label} cannot be blank.').label('Performed By'),
});
