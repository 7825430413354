import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../constans/formats';
import { HeaderName, StyledDelete } from '../../../components/EditableTable/styles';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import { Box, Tooltip } from '@mui/material';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import ButtonIcon from '../../../components/ButtonIcon';
import {
  OPENED,
  RETURNED,
  STATUSES,
  getFormatStatus,
  PAID,
  ACCEPTED,
  SUBMITTED,
  DRAFT
} from './constants';
import Button from '../../../components/Button';
import { GreenButton, RedButton, YellowButton } from '../../Logistics/style';
import Chip from '@mui/material/Chip';

const getFileButtonColor = (row) => {
  if (row?.report_status === PAID) {
    return GreenButton;
  }
  if (row?.report_status === ACCEPTED && row?.bank_file_id) {
    return YellowButton;
  }
  if (row?.report_status === SUBMITTED && !row?.bank_file_id) {
    return RedButton;
  }

  return {};
};

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
  handleFileDrawer,
  handleOpenEditForm
}) => ([
  {
    flex: 0.2,
    field: 'id',
    minWidth: 200,
    headerName: 'Record ID',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Record ID</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.id || ''}
          value="id"
          placeholder="Search by ID"
        />
      </Box>
    ),
    renderCell: ({ row: { id } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}
        variant="subtitle1"
        onClick={() => handleOpenEditForm(id)}
      >
        {`Report #${id}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'report_status',
    minWidth: 200,
    headerName: 'Current Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Current Status</Typography>
        <FiltrationSelect
          options={STATUSES || []}
          defaultValue={filters.report_status || ''}
          handleFilter={handleFilter}
          placeholder="Current Status"
          value="report_status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        <Chip label={getFormatStatus(row.report_status)} variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 180,
    headerName: 'Creation Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Creation Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'created_at', true)}
          value={filters.created_at || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {moment(row.created_at).format(DATE_FORMAT)}
      </Typography>
    ),
  },
  {
    flex: 0.15,
    field: 'files_exists',
    minWidth: 120,
    headerName: 'Files',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          sx={getFileButtonColor(row)}
          size="small"
          onClick={() => handleFileDrawer(true, row?.id, null, 'PersonalReportData', row?.id)}
        >
          Files
        </Button>
      </Box>
    ),
  },
  {
    width: 120,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {[OPENED, RETURNED, DRAFT].includes(row?.report_status) && (
          <ButtonIcon>
            <Tooltip title="Delete">
              <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
            </Tooltip>
          </ButtonIcon>
        )}
      </>
    ),
  },
]);
