
import AutocompleteMui from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import React, { forwardRef, useState } from 'react';
import { CloseIcon } from '../../../components/Icons';
import Input from '../Input';

const Autocomplete = forwardRef(
  (
    {
      options,
      placeholder,
      customIcon,
      isLoading,
      onChangeInput,
      popupIcon,
      sx,
      label,
      ...props
    },
    ref
  ) => {
    const [searchState, setSearchState] = useState('');
    const optionsWithSearch = searchState ? options.filter(item => item?.label?.includes(searchState)) : options;

    return (
      <AutocompleteMui
        {...props}
        ref={ref}
        isOptionEqualToValue={(option, value) => option.value === value?.value || option.label === value}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label)}
        onInputChange={(event, newInputValue) => {
          if (onChangeInput) {
            onChangeInput(newInputValue);
          }

          debounce(() => setSearchState(newInputValue), 400);
        }}
        clearIcon={<CloseIcon color="action" />}
        loading={isLoading}
        options={optionsWithSearch}
        size="small"
        sx={sx}
        renderInput={(params) => (
          <Input
            fullWidth
            label={label}
            sx={
                customIcon
                  ? {
                    '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                      right: 38,
                      top: 'calc(50% - 16px)'
                    },
                  }
                  : {}
              }
            placeholder={placeholder}
            {...params}
            // * In future if need custom icon
            // InputProps={
            //     customIcon
            //       ? {
            //         ...params.InputProps,
            //         endAdornment: (
            //           <Box>
            //             {params.InputProps.endAdornment}
            //             <Grid
            //               container
            //               alignItems="center"
            //               justifyContent="center"
            //               sx={{}}
            //             >
            //               {customIcon}
            //             </Grid>
            //           </Box>
            //         ),
            //       }
            //       : params.InputProps
            //   }
          />
        )}
        popupIcon={popupIcon || false}
      />
    );
  }
);

export default Autocomplete;
