import {
  useConvertPersonalCurrenciesMutation,
  useSendPersonalReportMutation,
  useGetPersonalReportQuery,
  useUpdatePersonalReportMutation,
  useAcceptPersonalReportFinanceMutation
} from '../../../../../store/slices/personalReportsSlice';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './schema';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  useCallback, useEffect, useRef, useState
} from 'react';
import { debounce } from 'lodash';
import { fileToBase64 } from '../../../../../utils/utils';
import { fetchCurrencies } from '../../../../../store/slices/logisticsSlice';
import { useAppDispatch } from '../../../../../hooks/toolkitHooks';
import {
  SUBMITTED,
  DECLINED,
  OPENED,
  RETURNED,
  DRAFT
} from '../../constants';

export const usePersonalReport = (onClose, id) => {
  const update = !!id;
  const dispatch = useAppDispatch();
  const [fileNames, setFileNames] = useState([]);
  const [bankFileName, setBankFileName] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const { currencies } = useSelector((state) => state.logistics);
  const [convertCurrencies] = useConvertPersonalCurrenciesMutation();
  const [storeReport] = useSendPersonalReportMutation();
  const [updateReport] = useUpdatePersonalReportMutation();
  const [acceptReport] = useAcceptPersonalReportFinanceMutation();
  const { data: personalReportData, isLoading: personalReportLoading } = useGetPersonalReportQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id
  });
  const submitted = [SUBMITTED].includes(personalReportData?.report_status);
  const declined = [DECLINED].includes(personalReportData?.report_status);
  const opened = [OPENED].includes(personalReportData?.report_status);
  const returned = [RETURNED].includes(personalReportData?.report_status);
  const draft = [DRAFT].includes(personalReportData?.report_status);

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, []);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const hasComment = (index) => update && (form.getValues(`rows.${index}.comments`) && personalReportData?.report_status === 'returned');

  const {
    fields: rowFields,
    append: rowAppend,
    remove: rowRemove
  } = useFieldArray({
    name: 'rows',
    control: form.control,
  });

  const rowsWatcher = useWatch({
    name: 'rows',
    control: form.control,
  });

  useEffect(() => {
    if (personalReportData && !personalReportLoading && update) {
      const formattedRows = personalReportData.rows.map((row) => ({
        ...row,
        file: row?.file
          ? {
            content: row?.file.content || '',
            name: row?.file.name || '',
            extension: row?.file.extension || '',
          }
          : null,
      }));

      form.reset({
        rows: formattedRows,
        ...personalReportData,
        bank_file: null
      });

      setFileNames(
        formattedRows.map((row, index) => ({
          id: index,
          name: row?.file?.name || '',
        }))
      );
    }
  }, [personalReportData, personalReportLoading, form, update]);

  const previousRows = useRef([]);
  const handleConvertCurrencies = useCallback(
    debounce(async (data) => {
      const res = await convertCurrencies(data).unwrap();
      setTotalAmount(parseFloat(res?.amount) || 0);
    }, 1000),
    [convertCurrencies]
  );
  useEffect(() => {
    if (rowsWatcher) {
      const hasChanged = rowsWatcher.some((row, index) => {
        const prevRow = previousRows.current[index] || {};

        return (
          row.amount !== prevRow.amount
          || row.rate !== prevRow.rate
          || row.rate_currency_id !== prevRow.rate_currency_id
        );
      });

      if (hasChanged) {
        const validRows = rowsWatcher.filter(
          (row) => row.amount
            && row.rate
            && row.rate_currency_id
            && !isNaN(parseFloat(row.amount))
            && !isNaN(parseFloat(row.rate))
        );

        if (validRows.length > 0) {
          const payload = {
            data: validRows.map((row) => ({
              amount: parseFloat(row.amount),
              rate: parseFloat(row.rate),
              currency_id: parseInt(row.rate_currency_id, 10),
            })),
          };

          handleConvertCurrencies(payload);
        }
      }

      previousRows.current = rowsWatcher;
    }
  }, [rowsWatcher, convertCurrencies]);

  const handleCloseOnSubmit = (clear = true) => {
    if (clear) {
      setTotalAmount(0);
      setFileNames([]);
      form.reset(defaultValues);
    }
    onClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    if (submitted || declined) {
      const res = await acceptReport({
        id,
        data: {
          file: {
            content: data.bank_file.content,
            name: data.bank_file.name,
            extension: data.bank_file.extension,
          }
        }
      });

      if (res?.error) {
        toast.error(res?.error?.data?.message, {
          id: toastId,
          duration: 15000
        });

        return;
      }
    } else if (update) {
      if (form.getValues('update_draft')) {
        data.update_draft = true;
      }

      const res = await updateReport({ id, data });
      if (res?.error) {
        toast.error(res?.error?.data?.message, {
          id: toastId,
          duration: 15000
        });

        return;
      }
    } else {
      const res = await storeReport(data);
      if (res?.error) {
        toast.error(res?.error?.data?.message, {
          id: toastId,
          duration: 15000
        });

        return;
      }
    }

    handleCloseOnSubmit();
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleUploadFile = async (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const fileAsBase64 = await fileToBase64(file);

      setFileNames((prevFileNames) => [
        ...prevFileNames.filter((item) => item.id !== index),
        { id: index, name: file.name },
      ]);

      form.setValue(`rows.${index}.file`, {
        content: fileAsBase64.base64,
        name: fileAsBase64.name,
        extension: fileAsBase64.extension
      });
    }
  };

  const handleResetFile = (index) => {
    setFileNames((prevFileNames) => prevFileNames.filter((item) => item.id !== index));
    form.setValue(`rows.${index}.file`, null);
  };

  const handleUploadBankFile = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileAsBase64 = await fileToBase64(file);
      setBankFileName(file.name);

      form.setValue('bank_file', {
        content: fileAsBase64.base64,
        name: fileAsBase64.name,
        extension: fileAsBase64.extension
      });
    }
  };

  const handleResetBankFile = () => {
    setBankFileName('');
    form.setValue('bank_file', null);
  };

  return {
    form,
    rowFields,
    rowAppend,
    rowRemove,
    onSubmit,
    update,
    currencies,
    handleUploadFile,
    handleResetFile,
    fileNames,
    totalAmount,
    handleCloseOnSubmit,
    personalReportData,
    hasComment,
    submitted,
    declined,
    opened,
    returned,
    draft,
    handleUploadBankFile,
    handleResetBankFile,
    bankFileName
  };
};
