import React from 'react';

// ** MUI Imports
import MuiTimeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/Button';

import ClearIcon from '@mui/icons-material/Clear';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { IconButton } from '@mui/material';
import { changeNotificationText, handleOpen, handleOpenFile } from '../../../components/NotificationDropdown/NotificationDropdownItem';
import { useOpenNotificationFileMutation } from '../../../store/session';

// Styled Timeline component
const Timeline = styled(MuiTimeline)({
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiTimelineItem-root': {
    width: '100%',
    '&:before': {
      display: 'none'
    }
  }
});

const ActivityTimeline = ({ data, handleRead, handleReadAll }) => {
  const [openFile, { isLoading: isFileOpening }] = useOpenNotificationFileMutation();
  const location = useLocation();

  return (
    <Card>
      <CardHeader
        title="Notifications"
        sx={{ '& .MuiCardHeader-avatar': { mr: 2.5 } }}
        avatar={<FormatListBulletedIcon />}
        action={(
          <Button disabled={!data?.data?.length} sx={{ mr: '1.25rem' }} size="small" onClick={() => handleReadAll()}>
            Clear All
          </Button>
        )}
        titleTypographyProps={{ sx: { color: 'text.primary' } }}
      />
      <CardContent>
        <Timeline sx={{ my: 0, py: 0 }}>

          {(data?.data || [])?.map((notification, index) => {
            const isPartArray = Array.isArray(notification?.data);

            return (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="warning" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ mt: 0, mb: theme => `${theme.spacing(2)} !important` }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={{ mr: 2, fontWeight: 500 }}>{notification?.data?.subject || notification?.data?.[0]?.subject}</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                        {notification?.notification_date}
                      </Typography>
                      <IconButton color="primary" onClick={() => handleRead(notification?.id)}>
                        <ClearIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  {isPartArray ? (notification?.data || [])?.map((item) => (
                    <>
                      <Typography sx={{ mb: 2, color: 'text.secondary' }}>{changeNotificationText(item?.message)}</Typography>
                      {item?.url?.length && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <Button size="small" onClick={() => handleOpen(item?.url, true, item?.message)}>Open</Button>
                      </Box>
                      )}
                    </>
                  )) : (
                    <>
                      <Typography sx={{ mb: 2, color: 'text.secondary' }}>{changeNotificationText(notification?.data?.message)}</Typography>
                      {notification?.data?.maintenance_invoice_part_id && (
                      <Box sx={{ mt: 3 }}>
                        <Button size="small" onClick={() => handleOpen(location.pathname, false, '', notification?.data?.maintenance_invoice_part_id)}>Order Again</Button>
                      </Box>
                      )}
                      {notification?.data?.url?.length && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button size="small" onClick={() => handleOpen(notification?.data?.url, false, notification?.data?.message)}>Open</Button>
                      </Box>
                      )}
                      {notification?.data?.ods_print_path && (
                      <Box sx={{ mt: 3 }}>
                        <Button size="small" disabled={isFileOpening} onClick={() => handleOpenFile(notification?.data?.ods_print_path, openFile)}>Open File</Button>
                      </Box>
                      )}
                    </>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })}

        </Timeline>
      </CardContent>
    </Card>
  ); };

export default ActivityTimeline;
