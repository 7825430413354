export const RowStyle = {
  padding: '1rem',
  marginY: '1rem',
  backgroundColor: 'rgba(29,69,113,0.06)'
};

export const CommentsStyle = {
  padding: '1rem',
  marginY: '1rem',
  backgroundColor: '#f2f4f6',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
};
