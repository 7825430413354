import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Breadcrumbs, Card, CircularProgress, Grid
} from '@mui/material';
import {
  BoxHeader, BoxHeaderFirst, CardHeaderSx, MainHeader
} from '../../InvoiceManagement/styles';
import Typography from '@mui/material/Typography';
import FlagIcon from '@mui/icons-material/Flag';
import LinkButton from '../../../../components/LinkButton';
import {
  useGetFinanceCrewProfileQuery,
  useGetFinanceCrewReportsTableQuery,
  useGetPersonalReportFilesMutation,
  useOpenPersonalReportFileMutation
} from '../../../../store/slices/personalReportsSlice';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import EditableTable from '../../../../components/EditableTable';
import { columns } from './columns';
import {setFilesName} from "../../../../utils/constants/portalFiles";
import toast from "react-hot-toast";
import FileDrawer from "../../../TrainingModule/FileDrawer/FileDrawer";

const PilotReports = () => {
  const { id } = useParams();
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: '',
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const { data: crew, isLoading: crewProfileLoading } = useGetFinanceCrewProfileQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id
  });
  const [getFiles, { isLoading: isLoadingFiles }] = useGetPersonalReportFilesMutation();

  const getFileList = async (id, callback) => {
    const res = await getFiles({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  }

  return (
    <Box>
      {/* Page Header */}
      <Card sx={{ ...CardHeaderSx, mb: 5 }}>
        <Box sx={BoxHeader}>
          <Box sx={BoxHeaderFirst}>
            <Typography noWrap variant="h4" sx={MainHeader}>
              <FlagIcon />
              Personal Reports
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkButton path="/finance/personal-reports" style={{ display: 'flex', alignItems: 'center' }}>
              <GroupIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Crews
            </LinkButton>
            <LinkButton path={`/finance/personal-reports/pilot/${id}`} style={{ display: 'flex', alignItems: 'center' }}>
              <PersonIcon sx={{ mr: 0.5, color: 'rgb(28 70 117)' }} fontSize="inherit" />
              <Typography sx={{ fontWeight: '600' }}>{`${crew?.crew_shortname || ''} Reports`}</Typography>
            </LinkButton>
          </Breadcrumbs>
        </Box>
      </Card>

      {/* Page Content */}
      <Box>
        {crewProfileLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <EditableTable
                  useHook={useGetFinanceCrewReportsTableQuery}
                  columns={columns}
                  sortByType="DESC"
                  sortByActive="id"
                  getRowClassName={({ id }) => `row-m ${id}`}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.id}
                  tableParams={{
                    crew_id: id
                  }}
                  handleFileDrawer={handleFileDrawer}
                  crewId={id}
                />
              </Card>
            </Grid>

            <FileDrawer
              hasUploadButton={false}
              hasDeleteButton={false}
              open={fileDrawerState.isOpen}
              onClose={() => handleFileDrawer(false)}
              title={fileDrawerState.name}
              itemName={fileDrawerState.currentName}
              itemsList={fileDrawerState.fileList}
              hasPermissions
              isLoadingFiles={isLoadingFiles}
              useOpenFileMutation={useOpenPersonalReportFileMutation}
            />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PilotReports;
