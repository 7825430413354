import ArchiveIcon from '@mui/icons-material/Archive';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  Box,
  Card,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import ArchivedDeclined from './ArchivedDeclined/ArchivedDeclined';
import Dashboard from './Dashboard/Dashboard';
import PersonalDashboard from './Dashboard/PersonalDashboard';
import InvoiceFileDrawer from './InvoiceFileDrawer/InvoiceFileDrawer';
import MyInvoices from './MyInvoices/MyInvoices';
import {
  BoxHeader,
  BoxHeaderFirst,
  CardHeaderSx, MainHeader
} from './styles';
import { useInvoiceManagement } from './useInvoiceManagement';
import Settings from '../LogisticStatistics/Settings';

const InvoiceManagement = () => {
  const invoiceProps = useInvoiceManagement();
  const [tabsState, setTabsState] = useState('board');

  // Permissions
  const invoicePermission = usePermissions(['invoice_request_access', 'invoice_request_logist', 'internal_invoice_request_logist']);
  const archivedDeclinedPermission = usePermissions('invoice_request_payment');

  const handleTabsChange = (event, newValue) => {
    setTabsState(newValue);

    if (newValue === 'board') {
      invoiceProps?.refetchBoard();
    }
    if (newValue === 'personal') {
      invoiceProps?.refetchBoardPersonal();
    }
  };

  return (
    <Box>
      {/* Page Header */}
      <Card sx={{ ...CardHeaderSx, mb: 5 }}>
        <Box sx={BoxHeader}>
          <Box sx={BoxHeaderFirst}>
            <Typography noWrap variant="h4" sx={MainHeader}>
              <DashboardCustomizeIcon />
              Invoice Management
            </Typography>
          </Box>
        </Box>
      </Card>

      <Settings changeButton={false} queryParams={{ refresh: invoiceProps.refetchLimit }} />

      <Box>
        <Tabs
          value={tabsState}
          onChange={handleTabsChange}
        >
          <Tab value="board" label="Dashboard" icon={<DashboardIcon />} />
          <Tab value="personal" label="Reports" icon={<PersonIcon />} />
          <Tab value="my_invoices" label="My Invoices" icon={<ReceiptIcon />} disabled={!invoicePermission} />
          <Tab value="archive" label="Archived" icon={<ArchiveIcon />} disabled={!archivedDeclinedPermission} />
          <Tab value="decline" label="Declined" icon={<HighlightOffIcon />} disabled={!archivedDeclinedPermission} />
        </Tabs>
      </Box>

      {/* Invoice Dashboard */}
      {(tabsState === 'board') && <Dashboard props={invoiceProps} />}
      {/* Personal Invoice Dashboard */}
      {(tabsState === 'personal') && <PersonalDashboard props={invoiceProps} />}
      {/* My Invoices Table */}
      {(tabsState === 'my_invoices') && <MyInvoices {...invoiceProps} />}
      {/* Archived & Declined Table */}
      {((tabsState === 'archive') || (tabsState === 'decline')) && <ArchivedDeclined type={tabsState} invoiceProps={invoiceProps} />}

      <InvoiceFileDrawer invoiceProps={invoiceProps} />
    </Box>
  );
};

export default InvoiceManagement;
