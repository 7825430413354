import {
  Box, Card, Divider, Grid
} from '@mui/material';
import React from 'react';
import { Filters } from '../../../../../Logistics/style';
import ApproveButton from '../ApproveButton';
import DeclineButton from '../DeclineButton';
import ApproveForwardButton from '../ApproveForwardButton';
import PdfViewer from '../../../../../../components/PdFViewer';
import { usePermissions } from '../../../../../../utils/hooks/usePermissions';

const PendingPaymentForm = ({ invoiceId, invoiceType, s3Link, onSubmit }) => {
  const forwardAccess = usePermissions('invoice_request_approve_forward_access');

  return (
    <Card sx={{ padding: '0rem 1rem 1rem 1rem' }}>
      <Grid container>
        <Grid item xs={12} sx={{ minHeight: '750px' }}>
          <PdfViewer s3Link={s3Link} />
        </Grid>

        <Grid item xs={12} sx={{ padding: '1rem 0rem 0rem 0rem' }}>
          <Divider />
          <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
            <DeclineButton invoiceId={invoiceId} onSubmit={onSubmit} />
            {(forwardAccess && invoiceType !== 'request') && <ApproveForwardButton invoiceId={invoiceId} onSubmit={onSubmit} />}
            <ApproveButton invoiceId={invoiceId} onSubmit={onSubmit} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PendingPaymentForm;
