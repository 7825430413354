import {
  number,
  object,
  string
} from 'yup';

export const defaultValues = {
  title: '',
  description: '',
  priority: 0,
  planning_date: null,
  implementation_date: null,
};

export const schema = object().shape({
  title: string()
    .required()
    .label('Planning Title'),
  description: string()
    .required()
    .label('Planning Description'),
  priority: number()
    .required()
    .label('Priority'),
  planning_date: string()
    .nullable()
    .label('Estimate date'),
  implementation_date: string()
    .nullable()
    .label('Implementation date')
});
