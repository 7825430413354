import React from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Box,
  Typography,
  useMediaQuery,
  Divider, TextField, CircularProgress,
} from '@mui/material';
import { headerBtn, headerBtnS } from '../../../Invoices/style';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import CloseIcon from '@mui/icons-material/Close';
import { usePlanningForm } from './usePlanningForm';
import FormGroup from '../../../../form/components/FormGroup';
import { Controller } from 'react-hook-form';
import Button from '../../../../components/Button';
import { button } from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/style';
import Select from '../../../../form/components/Select';
import { PLANNING_PRIORITIES } from '../../constants';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import DatePicker from '../../../../form/components/DatePicker';
import { WhiteInput } from '../../../Finance/InvoiceManagement/styles';
import { GreenButton, RedButton } from '../../../Logistics/style';
import toast from 'react-hot-toast';

const PlanningForm = ({
  open = false, onClose, taskID, onRefetch
}) => {
  const onError = (error) => {
    toast.error(error);
  };

  const onSuccess = () => {
    toast.success('Success!');
    reset();
    onClose();
    onRefetch();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const {
    taskLoading,
    taskData,
    onSubmit,
    handleCompleteTask,
    handleDestroyTask,
    update,
    form: {
      getValues,
      setValue,
      reset,
      control,
      handleSubmit,
      formState: { errors }
    }
  } = usePlanningForm(taskID, open, onError, onSuccess, handleClose);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={!!open}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {taskLoading ? (
          <DialogContent sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </DialogContent>
        ) : (

          <DialogContent>
            <Box sx={{
              ...headerBtn, ...(maxWidth1000px && headerBtnS), display: 'flex', justifyContent: 'space-between'
            }}
            >
              <Box>
                <Typography variant="h5" noWrap>
                  {taskData?.title}
                </Typography>
              </Box>

              <CloseIcon
                sx={CloseIconStyle}
                onClick={handleClose}
              />
            </Box>

            <Divider />

            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <FormGroup label="Task Title" hasError={!!errors.title}>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="Task Title"
                        size="small"
                      />
                    )}
                  >
                  </Controller>
                </FormGroup>
              </Grid>

              <Grid item md={5} xs={12}>
                <FormGroup label="Priority" hasError={!!errors.priority}>
                  <Controller
                    name="priority"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={PLANNING_PRIORITIES}
                        placeholder="Priority"
                        {...field}
                      />
                    )}
                  >
                  </Controller>
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Desciption" hasError={!!errors.description}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        rows={6}
                        placeholder="Description"
                        size="small"
                      />
                    )}
                  >
                  </Controller>
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup
                  label="Estimate date"
                  hasError={!!errors.planning_date}
                >
                  <Controller
                    name="planning_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        {...field}
                        sx={{
                          ...(WhiteInput),
                          '& > .react-datepicker__tab-loop': {
                            position: 'fixed',
                            zIndex: 2000
                          }
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'planning_date', 'YYYY-MM-DD');
                        }}
                        value={validateDatePickerValue(field.value) || null}
                      />
                    )}
                  >
                  </Controller>
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup label="Implementation date" hasError={!!errors.implementation_date}>
                  <Controller
                    name="implementation_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        {...field}
                        sx={{
                          ...(WhiteInput),
                          '& > .react-datepicker__tab-loop': {
                            position: 'fixed',
                            zIndex: 2000
                          }
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'implementation_date', 'YYYY-MM-DD');
                        }}
                        value={validateDatePickerValue(field.value) || null}
                      />
                    )}
                  >
                  </Controller>
                </FormGroup>
              </Grid>

              {taskData?.completed_at && (
                <Grid item xs={12} md={6}>
                  <FormGroup label="Completed at" hasError={!!errors.completed_at}>
                    <Controller
                      name="completed_at"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          {...field}
                          sx={{
                            ...(WhiteInput),
                            '& > .react-datepicker__tab-loop': {
                              position: 'fixed',
                              zIndex: 2000
                            }
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            setDateValue(e, setValue, 'completed_at', 'YYYY-MM-DD');
                          }}
                          value={validateDatePickerValue(field.value) || null}
                        />
                      )}
                    >
                    </Controller>
                  </FormGroup>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',

                }}
                >
                  {update && (<Button sx={{ ...(button), ...(RedButton) }} title="Delete" onClick={() => handleDestroyTask(taskData?.id)} />)}
                  {(!taskData?.completed_at && update) && (<Button sx={{ ...(button), ...(GreenButton) }} title="Complete" type="submit" onClick={() => handleCompleteTask(taskData?.id)} />)}
                  <Button sx={button} title={update ? 'Update' : 'Create'} type="submit" />
                </Box>
              </Grid>
            </Grid>

          </DialogContent>
        )}
      </form>
    </Dialog>
  );
};

export default PlanningForm;
