import {
  array,
  object,
  string
} from 'yup';

export const defaultValues = {
  items: [
    {
      id: '',
      storage_number: '',
      serial_number: '',
      storage_zone: '',
      expected_day_delivery: '',
      certificate: null
    }
  ]
};

export const defaultTTMValues = {
  ttm_code: '',
};

export const TTMschema = object().shape({
  ttm_code: string()
    .label('TTM Code')
});

export const schema = object().shape({
  items: array(object().shape({
    id: string().nullable(),
    storage_number: string()
      .required()
      .label('Storage number'),
    serial_number: string()
      .nullable()
      .label('Serial number'),
    expected_day_delivery: string()
      .nullable()
      .label('Serial number'),
    storage_zone: string()
      .nullable()
      .label('Serial number')
  }))
});

export const internalSchema = object().shape({
  type: string().nullable(),
  items: array(object().shape({
    type: string().nullable(),
    expected_day_delivery: string()
      .when('type', {
        is: (val) => ['Repair_in', 'Overhaul_in', 'delivery'].includes(val),
        then: string().required('${label} cannot be blank.'),
        otherwise: string().nullable()
      })
      .label('Estimated Day Delivery')
  }))
});
