/**
 * Check for URL queries as well for matching
 * Current URL & Item Path
 *
 * @param router
 * @param path
 */
export const handleURLQueries = (router, path) => {
  if (Object.keys(router.query).length && path) {
    const arr = Object.keys(router.query);

    return router.asPath.includes(path) && router.asPath.includes(router.query[arr[0]]) && path !== '/';
  }

  return false;
};

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param currentURL
 */
// eslint-disable-next-line consistent-return
export const hasActiveChild = (item, currentURL) => {
  const { children } = item;
  if (!children) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const child of children) {
    if (child.children) {
      if (hasActiveChild(child, currentURL)) {
        return true;
      }
    }
    const childPath = child.path;

    // Check if the child has a link and is active
    if (
      child
      && childPath
      && currentURL
      && (childPath === currentURL || (currentURL.includes(childPath) && childPath !== '/'))
    ) {
      return true;
    }
  }
};

/**
 * Check if this is a children
 * of the given item
 *
 * @param children
 * @param openGroup
 * @param currentActiveGroup
 */
export const removeChildren = (children, openGroup, currentActiveGroup) => {
  children.forEach((child) => {
    if (!currentActiveGroup.includes(child.title)) {
      const index = openGroup.indexOf(child.title);
      if (index > -1) openGroup.splice(index, 1);

      // @ts-ignore
      if (child.children) removeChildren(child.children, openGroup, currentActiveGroup);
    }
  });
};

export const fileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64String = reader.result.split(',')[1];
    const extension = file.name.split('.').pop();
    resolve({
      base64: base64String,
      extension,
      name: file.name,
    });
  };
  reader.onerror = (error) => reject(error);
});

export const openFileInNewTab = (base64Content, mimeType = 'application/octet-stream') => {
  try {
    const byteCharacters = atob(base64Content);
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');

    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 1000);
  } catch (error) {
    console.error('Failed to open file:', error);
  }
};
