import { Box } from '@mui/material';
import moment from 'moment/moment';
import React from 'react';
import Button from '../../../../components/Button';
import { OdsActionDocumentModel } from '../../../../utils/constants/portalFiles';
import { GreenButton } from '../../../Logistics/style';

export const columns = ({
  handleFileDrawer,
  handleSetDrawerState,
  createActionPermission
}) => ([
  {
    id: 'defect_text',
    name: 'DEFECT',
  },
  {
    id: 'action',
    name: 'ACTION',
  },
  {
    id: 'ata_text',
    name: 'ATA',
  },
  // {
  //   id: 'part_off',
  //   name: 'PART OFF',
  // },
  // {
  //   id: 'part_on',
  //   name: 'PART ON',
  // },
  {
    id: 'note',
    name: 'NOTE',
  },
  {
    id: 'performed_crew_shortname',
    name: 'PERFORMED BY',
  },
  {
    id: 'performed_sign',
    name: 'SIGN',
    cell: ({ performed_sign }) => (performed_sign ? <img style={{ width: '80px', height: '60px', margin: '5px' }} src={performed_sign} alt="perf-sign-img" /> : 'N/A')
  },
  {
    id: 'performed',
    name: 'DATE',
    cell: ({ performed }) => (performed ? moment(performed).format('YYYY-MM-DD HH:mm') : '')
  },
  {
    id: 're_performed_crew_id',
    name: 'RE-INSPECTION: PERFORMED BY',
    cell: ({ re_performed_crew_shortname }) => re_performed_crew_shortname || 'N/A'
  },
  {
    id: 're_performed_sign',
    name: 'RE-INSPECTION: SIGN',
    cell: ({ re_performed_sign }) => (re_performed_sign ? <img style={{ width: '80px', height: '60px', margin: '5px' }} src={re_performed_sign} alt="perf-sign-img" /> : 'N/A')
  },
  {
    id: 're_performed_date',
    name: 'RE-INSPECTION DATE',
    cell: ({ re_performed_date }) => (re_performed_date ? moment(re_performed_date).format('YYYY-MM-DD HH:mm') : 'N/A')
  },
  {
    id: 'files',
    name: 'Files',
    cell: ({ id }) => (
      <Box sx={{ width: '80px', margin: '0, 6px' }}>
        <Button sx={GreenButton} size="small" onClick={() => handleFileDrawer(true, id, OdsActionDocumentModel.types.action.id, 'Action', '')}>Files</Button>
      </Box>
    )
  },
  {
    id: 'part_off_part_on',
    name: 'Part Off & Part On',
    cell: ({
      id, action, part_off, part_on 
    }) => (
      <Box sx={{
        width: '100%', margin: '0, 6px', display: 'flex', justifyContent: 'center' 
      }}
      >
        <Button 
          sx={GreenButton} 
          size="small" 
          disabled={!createActionPermission}
          onClick={() => handleSetDrawerState(true, id, action, { part_off, part_on })}
        >
          Open Table
        </Button>
      </Box>
    )
  },
]);
