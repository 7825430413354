import { PLANNING_PRIORITIES } from '../constants';
import { useMemo, useState } from 'react';
import { usePermissions } from '../../../utils/hooks/usePermissions';

export const useCurrentTasksGrid = (variant, data) => {
  const hasPermissionWrite = usePermissions('plannings_write');
  const [openModal, setOpenModal] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);

  const handlePriority = (priority) => PLANNING_PRIORITIES.find((p) => p.value === priority)?.icon;

  const tasks = useMemo(() => {
    if (!data.length) return [];
    const ongoingTasks = data.filter(
      (task) => !task.completed_at && (task.implementation_date || task.planning_date)
    );
    const plannedTasks = data.filter(
      (task) => !task.completed_at && !task.implementation_date && !task.planning_date
    );
    const completedTasks = data.filter((task) => task.completed_at != null);

    switch (variant) {
      case 'future':
        return plannedTasks;
      case 'completed':
        return completedTasks;
      default:
        return ongoingTasks;
    }
  }, [data, variant]);

  const handleCloseTask = () => {
    setCurrentTaskId(null);
    setOpenModal(false);
  };

  const handleOpenTask = (taskID) => {
    if (!hasPermissionWrite) return;

    setCurrentTaskId(taskID);
    setOpenModal(true);
  };

  return {
    handlePriority,
    tasks,
    currentTaskId,
    setCurrentTaskId,
    handleCloseTask,
    handleOpenTask,
    openModal,
    hasPermissionWrite
  };
};
