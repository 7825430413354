export function numberUnFormat(formatted, positive = false) {
  if (formatted == null) {
    return '';
  }

  const isNegative = formatted.indexOf('-') === 0;
  const number = formatted.replace(/[^\d.]/g, '');
  const lastDot = number.slice(-1) === '.';
  const [integer, fractional] = number.split('.');
  const result = fractional ? `${integer}.${fractional}` : lastDot ? `${integer}.` : integer;

  return positive ? result : isNegative ? `-${result}` : result;
}

export const selectOptionFormat = (array, keyLabel) => array?.map((item) => ({
  label: item[keyLabel],
  value: item.id,
})) || [];

export const round = (number, precision = 100) => {
  if (typeof number !== 'number') return '';

  return Math.ceil(number / precision) * precision;
};

export const numberFormatValidate = (number, total = 13, places = 2) => {
  const regex = new RegExp(`^\\d{0,${total}}(\\.\\d{0,${places}})?$`);

  return regex.test(number);
};

export const formatNumber = (number, places = 2) => {
  if (number == null) {
    return '';
  }

  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: places,
  });
};
