import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  Card,
  Divider,
  Tab
} from '@mui/material';
import {
  btnS, headerBtn, headerBtnS, headerBtnXs
} from '../Invoices/style';
import Button from '../../components/Button';
import { GreenButton } from '../Logistics/style';
import { usePlanning } from './usePlanning';
import CurrentTasksGrid from './components/CurrentTasksGrid';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import UpdateIcon from '@mui/icons-material/Update';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CheckIcon from '@mui/icons-material/Check';
import PlanningForm from './components/form/PlanningForm';

const Planning = () => {
  const [currentTab, setCurrentTab] = useState('current');

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const {
    maxWidth1000px,
    maxWidth800px,
    hasPermissionWrite,
    tasks,
    openStore,
    setOpenStore,
    refetchTasks
  } = usePlanning();

  return (
    <>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box
          sx={{
            ...headerBtn, ...(maxWidth1000px && headerBtnS), display: 'flex', justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography variant="h3">IT Planning</Typography>
          </Box>

          <Box sx={{ ...(maxWidth800px && { ...headerBtnXs, width: '100%' }) }}>
            {hasPermissionWrite && (
              <Button
                sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS), ...GreenButton }}
                title="Create"
                onClick={() => setOpenStore(true)}
              />
            )}
          </Box>
        </Box>
      </Card>

      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <TabContext value={currentTab}>
          <TabList
            onChange={handleChange}
            aria-label="tabs"
            sx={{ bgcolor: 'whitesmoke', minHeight: '96px' }}
            variant="fullWidth"
          >
            <Tab value="current" label="Current Tasks" icon={<EngineeringIcon />} />
            <Tab value="future" label="Future Tasks" icon={<UpdateIcon />} />
            <Tab value="completed" label="Completed Tasks" icon={<CheckIcon />} />
          </TabList>

          <Divider sx={{ mt: 0 }} />

          <TabPanel value="current">
            <CurrentTasksGrid data={tasks} maxWidth1000px={maxWidth1000px} onRefetch={refetchTasks} />
          </TabPanel>

          <TabPanel value="future">
            <CurrentTasksGrid data={tasks} maxWidth1000px={maxWidth1000px} variant="future" onRefetch={refetchTasks} />
          </TabPanel>

          <TabPanel value="completed">
            <CurrentTasksGrid data={tasks} maxWidth1000px={maxWidth1000px} variant="completed" onRefetch={refetchTasks} />
          </TabPanel>

        </TabContext>

        <PlanningForm open={openStore} onClose={() => setOpenStore(false)} taskID={null} onRefetch={refetchTasks} />
      </Card>
    </>

  );
};

export default Planning;
