import { useMediaQuery } from '@mui/material';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { useGetAllPlanningTasksQuery } from '../../store/slices/planningSlice';
import { useState } from 'react';

export const usePlanning = () => {
  const [openStore, setOpenStore] = useState(false);
  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const hasPermissionWrite = usePermissions('plannings_write');

  const { data: tasks, refetch: refetchTasks, isLoading: tasksLoading } = useGetAllPlanningTasksQuery();

  const isLoading = tasksLoading;

  return {
    maxWidth1000px,
    maxWidth800px,
    hasPermissionWrite,
    tasks,
    isLoading,
    openStore,
    setOpenStore,
    refetchTasks
  };
};
