import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import { HeaderName } from '../../../Caa/CaaTable/styles';
import LinkButton from '../../../../components/LinkButton';
import Chip from '@mui/material/Chip';
import moment from 'moment/moment';
import { DARE_FORMAT_FIRST } from '../../../../constans/formats';
import Button from '../../../../components/Button';
import { GreenButton, YellowButton, RedButton } from '../../../Logistics/style';
import {
  getFormatStatus, SUBMITTED, PAID, ACCEPTED
} from '../../../MyProfile/PersonalReportsTab/constants';

const getFileButtonColor = (row) => {
  if (row?.report_status === PAID) {
    return GreenButton;
  }
  if (row?.report_status === ACCEPTED && row?.bank_file_id) {
    return YellowButton;
  }
  if (row?.report_status === SUBMITTED && !row?.bank_file_id) {
    return RedButton;
  }

  return {};
};

export const columns = ({
  handleFilter,
  filters,
  handleFileDrawer,
  crewId
}) => ([
  {
    flex: 0.2,
    field: 'id',
    minWidth: 200,
    headerName: 'Report #',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Report #</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.id || ''}
          value="id"
          placeholder="Search by ID"
        />
      </Box>
    ),
    renderCell: ({ row: { id } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}
        variant="subtitle1"
      >
        <LinkButton path={`/finance/personal-reports/pilot/report/${crewId}/${id}`}>
          {`Report #${id}`}
        </LinkButton>
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'report_status',
    minWidth: 200,
    headerName: 'Current Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Current Status</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.report_status || ''}
          value="report_status"
          placeholder="Current Status"
        />
      </Box>
    ),
    renderCell: ({ row: { report_status } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces'
        }}
        variant="subtitle1"
      >
        <Chip label={getFormatStatus(report_status)} variant="filled" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 180,
    headerName: 'Creation Date',
    renderCell: ({ row }) => (
      <Typography>{moment(row.created_at).format(DARE_FORMAT_FIRST)}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'amount',
    minWidth: 200,
    headerName: 'Report Sum',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Report Sum</Typography>
      </Box>
    ),
    renderCell: ({ row: { rows } }) => (
      <Box sx={{ cursor: 'context-menu' }}>
        {rows?.map(( amount, index) => (
          <Typography noWrap key={index} variant="subtitle1">
            {`${amount.amount} ${amount.currency}`}
          </Typography>
        ))}
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'files_exists',
    minWidth: 120,
    headerName: 'Files',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          sx={getFileButtonColor(row)}
          size="small"
          onClick={() => handleFileDrawer(true, row?.id, null, 'PersonalReportData', row?.id)}
        >
          Files
        </Button>
      </Box>
    ),
  },
]);
