import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { TaskContainer } from '../styles';
import { headerBtn, headerBtnS } from '../../Invoices/style';
import { useCurrentTasksGrid } from './useCurrentTasksGrid';
import PlanningForm from './form/PlanningForm';

const CurrentTasksGrid = ({ data = [], maxWidth1000px = false, variant = 'current', onRefetch }) => {
  const {
    tasks,
    handlePriority,
    currentTaskId,
    openModal,
    handleCloseTask,
    handleOpenTask
  } = useCurrentTasksGrid(variant, data);

  const renderTask = (task) => (
    <Box sx={TaskContainer}>
      <Box
        sx={{
          ...headerBtn,
          ...(maxWidth1000px && headerBtnS),
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">{task?.title}</Typography>
        {handlePriority(task.priority)}
      </Box>
      {task.description && <Typography variant="subtitle2">{task.description}</Typography>}
      {(task.implementation_date && !task.completed_at) && (
        <Typography variant="subtitle1" color="orange">
          {`Implementation date: ${task.implementation_date}`}
        </Typography>
      )}
      {(!task.implementation_date && task.planning_date && !task.completed_at) && (
        <Typography variant="subtitle1" color="red">
          {`Understanding due date: ${task.planning_date}`}
        </Typography>
      )}
      {(task.completed_at) && (
        <Typography variant="subtitle1" color="green">
          {`Completed at: ${task.completed_at}`}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={6}>
        {tasks.map((task, index) => (
          <Grid key={task?.id || index} item lg={4} sm={12} md={6} onClick={() => handleOpenTask(task?.id)}>
            {renderTask(task)}
          </Grid>
        ))}
      </Grid>
      <PlanningForm
        open={openModal}
        onClose={handleCloseTask}
        taskID={currentTaskId}
        onRefetch={onRefetch}
      />
    </Box>
  );
};

export default CurrentTasksGrid;
