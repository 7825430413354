import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetFinanceCrewProfileQuery,
  useGetPersonalReportQuery,
  useUpdatePersonalReportFinanceMutation,
  useAcceptPersonalReportFinanceMutation
} from '../../../../store/slices/personalReportsSlice';
import {
  useGetCurrencyListQuery
} from '../../../../store/session'
import { useForm, useWatch } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { fileToBase64, openFileInNewTab } from '../../../../utils/utils';
import { OPENED, RETURNED, SUBMITTED } from '../../../MyProfile/PersonalReportsTab/constants';
import { toast } from 'react-hot-toast';
import {getOptions} from "../../../../utils/getOptions";

export const useReportForm = () => {
  const { crew_id, report_id } = useParams();
  const [submitName, setSubmitName] = useState('Reopen');
  const [fileName, setFileName] = useState('');
  const { data: currenciesData } = useGetCurrencyListQuery();
  const { data: crew, isLoading: crewProfileLoading } = useGetFinanceCrewProfileQuery(crew_id, {
    refetchOnMountOrArgChange: true,
    skip: !crew_id
  });
  const { data: personalReportData, isLoading: personalReportLoading } = useGetPersonalReportQuery(report_id, {
    refetchOnMountOrArgChange: true,
    skip: !report_id
  });
  const isLoading = crewProfileLoading || personalReportLoading;
  const showEditFields = [OPENED, RETURNED].includes(personalReportData?.report_status);
  const submitted = [SUBMITTED].includes(personalReportData?.report_status);
  const [updateReport] = useUpdatePersonalReportFinanceMutation();
  const [acceptReport] = useAcceptPersonalReportFinanceMutation();
  const currencies = getOptions(currenciesData);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const rowsWatcher = useWatch({
    name: 'rows',
    control: form.control,
  });

  useEffect(() => {
    if (personalReportData && !personalReportLoading) {
      const formattedRows = personalReportData.rows.map((row) => ({
        id: row?.id,
        comments: row?.comments || '',
        submitted_at: !!row?.submitted_at
      }));

      form.reset({
        bank_file_id: personalReportData?.bank_file_id,
        bank_file: {
          name: personalReportData?.bank_file?.name,
          content: personalReportData?.bank_file?.content,
          extension: personalReportData?.bank_file?.extension,
          mime_type: personalReportData?.bank_file?.mime_type
        },
        rows: formattedRows
      });
    }
  }, [personalReportData, personalReportLoading]);

  useEffect(() => {
    if (rowsWatcher && rowsWatcher.every(row => row.submitted_at)) {
      setSubmitName('Submit');
    } else {
      setSubmitName('Reopen');
    }
  }, [rowsWatcher]);

  const handleCurrencyLabelById = (id) => currencies?.find((currency) => currency.value == id)?.label || 'N/A';

  const handleOpenFile = (content, mimeType) => {
    openFileInNewTab(content, mimeType);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Processing...');

    if (showEditFields) {
      const res = await updateReport({
        id: report_id,
        data
      });

      if (res?.error) {
        toast.error(res?.error?.data?.message, {
          id: toastId,
          duration: 15000
        });

        return;
      }
    }

    if (submitted) {
      const res = await acceptReport({
        id: report_id,
        data: {
          file: {
            content: data.bank_file.content,
            name: data.bank_file.name,
            extension: data.bank_file.extension,
          }
        }
      });

      if (res?.error) {
        toast.error(res?.error?.data?.message, {
          id: toastId,
          duration: 15000
        });

        return;
      }
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleUploadBankFile = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileAsBase64 = await fileToBase64(file);
      setFileName(file.name);

      form.setValue('bank_file', {
        content: fileAsBase64.base64,
        name: fileAsBase64.name,
        extension: fileAsBase64.extension
      });
    }
  };

  const handleResetBankFile = () => {
    setFileName('');
    form.setValue('bank_file', null);
  };

  return {
    crew,
    personalReportData,
    isLoading,
    currencies,
    handleCurrencyLabelById,
    handleOpenFile,
    form,
    onSubmit,
    submitName,
    showEditFields,
    submitted,
    handleUploadBankFile,
    handleResetBankFile,
    fileName
  };
};
