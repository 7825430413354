export const TaskContainer = {
  backgroundColor: 'rgba(28,70,117,0.04)',
  padding: '8px',
  borderRadius: '4px',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#1c467526',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
  }
};
