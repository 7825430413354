import { Box, useMediaQuery } from '@mui/material';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../../../components/Button';
import EditableTable from '../../../../../components/EditableTable';
import {
  useDeleteWorkOrderTaskMutation,
  useGetTaskListQuery,
  useGetWorkOrderFilesMutation,
  useGetWorkOrderTaskQuery,
  useUpdateWorkOrderTaskMutation,
} from '../../../../../store/session';
import { getOptions } from '../../../../../utils/getOptions';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';
import CreateTaskDrawer from './CreateTaskDrawer';
import TaskDrawer from './TaskDrawer';
import { columns } from './columns';
import {
  buttonBlock,
  buttonBlockS,
  buttonBlockSmall,
  headerSmall,
  headerSx,
  tableContainer,
  taskBlock,
} from './style';

const TaskListTable = ({
  hasPermissionsWoEdit, woId, workOrder, handleFlightData, setTableFilter = () => {}, setPageOptions = () => {}, setTableSort = () => {}
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createDrawer, setCreateDrawer] = useState(false);

  const hasPermissions = usePermissions('wo_creator');
  const deleteJobPermission = usePermissions('job_delete_access');

  const { data: taskList } = useGetTaskListQuery(woId);
  const [getFiles, { isLoading: isDownloadingFiles }] = useGetWorkOrderFilesMutation();

  const taskListOpt = getOptions(taskList);

  const maxWidth768px = useMediaQuery('(max-width:768px)');
  const maxWidth625px = useMediaQuery('(max-width:625px)');

  const tableParams = { wo_id: workOrder?.id };

  const downloadZipFile = async () => {
    const wo_id = workOrder?.id;
    if (wo_id) {
      const toastId = toast.loading('Downloading...');
      await getFiles({ id: wo_id }).then((res) => {
        if (!res?.error) {
          if (res.data.size === 0) {
            toast.error("Work order jobs doesn't have any file.", {
              id: toastId,
            });
          } else {
            toast.success('Success!', {
              id: toastId,
            });

            FileSaver.saveAs(res.data, `${workOrder?.designation}.zip`);
          }
        } else {
          const reader = new FileReader();
          reader.readAsText(res?.error.data);
          reader.onload = () => {
            const obj = JSON.parse(reader.result);
            toast.error(obj.message, {
              id: toastId,
            });
          };
        }
      });
    }
  };

  const getStyles = (row) => {
    if (row.closed) {
      return 'row-m';
    }

    if (row.performed || row.inspected) {
      return 'row-yellow';
    }

    return 'row-cnld';
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleCloseCreateDrawer = () => {
    setCreateDrawer(false);
  };

  const handleOpenCreateDrawer = () => {
    setCreateDrawer(true);
  };

  return (
    <Box sx={taskBlock}>
      <Box sx={{ ...headerSx, ...(maxWidth768px && headerSmall), justifyContent: 'end' }}>
        <Box
          sx={{
            ...buttonBlock,
            ...(maxWidth625px && buttonBlockSmall),
            ...(maxWidth625px && buttonBlockS),
          }}
        >
          <Button
            title="Download all files"
            disabled={isDownloadingFiles}
            onClick={() => downloadZipFile()}
          />
          <Button
            disabled={!hasPermissions}
            onClick={handleOpen}
            title="Assign WO Task"
          />
          <Button
            disabled={!hasPermissions}
            onClick={handleOpenCreateDrawer}
            title="Add WO Task"
          />
        </Box>
      </Box>

      <EditableTable
        useHook={useGetWorkOrderTaskQuery}
        getRowClassName={(date) => `${getStyles(date.row)}`}
        useHookUpdate={useUpdateWorkOrderTaskMutation}
        useHookDelete={useDeleteWorkOrderTaskMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="name"
        hasPermissionsWoEdit={hasPermissionsWoEdit}
        deleteCallback={handleFlightData}
        setTableFilter={setTableFilter}
        deletePermission={deleteJobPermission}
        setPageOptions={setPageOptions}
        setTableSort={setTableSort}
        tableParams={tableParams}
        style={tableContainer}
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.id}
      />

      <TaskDrawer
        taskListOpt={taskListOpt}
        onClose={handleClose}
        open={openDrawer}
        aircraft_id={woId}
      />
      <CreateTaskDrawer
        woId={woId}
        onClose={handleCloseCreateDrawer}
        open={createDrawer}
      />
    </Box>
  );
};

export default TaskListTable;
