import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {
  Box, Card,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Drawer from '../../../components/Drawer';
import {
  GreenButton,
  RedButton,
  StatusHeader,
  TextWithIcon,
  TextWrap
} from '../../Logistics/style';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import Upload from '../../../components/Upload';
import {
  useDeletePortalFileMutation,
  useGetProofOfPaymentMutation,
  useOpenPortalFileMutation
} from '../../../store/session';

const FileDrawer = (props) => {
  const {
    open = true,
    onClose = () => {},
    title = '',
    itemName = '',
    itemsList = [],
    isAttachLoading = false,
    hasPermissions = false,
    uploadFile = () => {},
    isLoadingFiles,
    deleteFileCallback = () => {},
    currentOpenFile = null,
    hasUploadButton = true,
    hasDeleteButton = true,
    dragAndDrop = null,
    useOpenFileMutation = useOpenPortalFileMutation,
    useDeleteFileMutation = useDeletePortalFileMutation,
    downloadButton = false,
    customButton = false,
    customButtonFunction = () => {},
    drawerSx = {}
  } = props;
  const [dragState, setDragState] = useState({
    isFileDrag: false
  });
  const [drawerState, setDrawerState] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [disabledDownloadBtn, setDisabledDownloadBtn] = useState(false);
  const [disabledOpenBtn, setDisabledOpenBtn] = useState(false);
  const [openTrainingFiles, { isLoading: fileOpenLoading }] = useOpenFileMutation();
  const [deleteTrainingFiles, { isLoading: isDeleteLoading }] = useDeleteFileMutation();
  const [getProofOfPayment] = useGetProofOfPaymentMutation();

  const handleOpenFile = async (id, download = '', isInvoiceProof = false) => {
    if (download) setDisabledDownloadBtn(true);
    if (!download) setDisabledOpenBtn(true);
    const toastId = toast.loading('Loading...');
    setCurrentFile(id);
    let res = null;
    if (isInvoiceProof) {
      res = await getProofOfPayment(id);
    } else {
      res = await openTrainingFiles({ id });
    }
    if (!res) return;

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Unable to open file.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    setCurrentFile(null);
    const fileUrl = URL.createObjectURL(res.data);
    if (download) {
      const downloadLink = document.createElement('a');
      downloadLink.href = fileUrl;
      downloadLink.download = download;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      setDisabledDownloadBtn(false);
    } else {
      setDisabledOpenBtn(false);
      window.open(fileUrl, '_blank', 'noreferrer');
    }
  };

  const handleDeleteFile = async (id) => {
    const toastId = toast.loading('Loading...');
    setCurrentFile(id);
    const res = await deleteTrainingFiles({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });
    setCurrentFile(null);
    setDrawerState(prev => ([...prev.filter(item => item.id !== id)]));
    deleteFileCallback();
  };

  useEffect(() => {
    setDrawerState(itemsList);
  }, [open, itemsList]);

  return (
    <Drawer
      title={`${title} Files`}
      open={open}
      onClose={onClose}
      onDragOver={(e) => {
        e.preventDefault();
        if ((e?.target?.outerHTML?.includes('MuiDrawer-paper') || e?.target?.offsetParent?.outerHTML?.includes('MuiDrawer-paper')) && !dragState?.isFileDrag) {
          setDragState(prev => ({
            ...prev,
            isFileDrag: true
          }));
        }

        if (e?.target?.outerHTML?.includes('MuiBackdrop-root') && dragState?.isFileDrag) {
          setDragState(prev => ({
            ...prev,
            isFileDrag: false
          }));
        }
      }}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      sx={{
        position: 'relative',
        zIndex: '9998',
        '.MuiTypography-root': {
          margin: '0 auto'
        },
        ...drawerSx
      }}
    >
      <Card sx={{ padding: 4 }}>
        <Card sx={{
          gap: '15px',
          backgroundColor: '#e9f0fe',
          padding: '10px',
          borderRadius: '8px'
        }}
        >
          <Box>
            <Box>
              <Typography variant="h5" noWrap sx={TextWithIcon}>
                <DocumentScannerIcon />
                {`${itemName} Files`}
              </Typography>
              <Typography variant="subtitle2" noWrap>
                You can upload files
                {itemName?.length ? ` to ${itemName}` : ''}
                .
              </Typography>
            </Box>
          </Box>

          <Divider />

          {isLoadingFiles ? (
            <ContentPageLoader containerSx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
              '& > .MuiGrid-item': {
                margin: '0'
              }
            }}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {drawerState?.length ? (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      overflowY: 'auto',
                      maxHeight: '70vh'
                    }}
                  >
                    {drawerState.map((file, index) => (
                      <Grid item xs={12} key={file.id} sx={{ backgroundColor: 'white', margin: '10px', borderRadius: '8px' }}>
                        <Box sx={{
                          ...StatusHeader, alignItems: 'start', display: 'grid', gridTemplateColumns: '8fr 2fr', gap: '.5rem'
                        }}
                        >
                          <Box sx={{
                            display: 'grid', alignItems: 'start', width: '100%', gridTemplateColumns: '2rem 1fr'
                          }}
                          >
                            <Typography sx={{ mr: 2 }}>{`#${index + 1}.`}</Typography>
                            <Box>
                              <Typography sx={{ ...TextWrap, fontWeight: '700', margin: '0!important' }}>
                                {file?.name || file?.file_name}
                              </Typography>
                              {file?.transaction_id ? (

                                <Box sx={{
                                  display: 'flex', justifyContent: 'flex-start', gap: '1rem', mt: 2
                                }}
                                >
                                  {' '}
                                  <Typography sx={{ margin: '0!important' }}>Transaction ID:</Typography>
                                  <Link to={`/finance/transactions?search_transaction=${file?.transaction_id}`} target="_blank">
                                    <Box>
                                      {file?.transaction_id}
                                    </Box>
                                  </Link>
                                </Box>
                              ) : ''}

                            </Box>
                          </Box>

                          <Box sx={{
                            margin: '0.2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            gap: 2

                          }}
                          >
                            {customButton && (
                              <Button
                                key={file.id}
                                sx={{
                                  ...(!file?.transaction_id ? RedButton : GreenButton), margin: '0', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}
                                startIcon="+"
                                title="Set Transaction"
                                size="small"
                                onClick={() => customButtonFunction(file.id, file?.transaction_id)}
                              />
                            )}
                            {downloadButton && (
                              <Button
                                key={file.id}
                                sx={{ ...(GreenButton), margin: '0', width: '100%' }}
                                startIcon={(disabledDownloadBtn && (currentFile === file?.id)) ? <CircularProgress size={15} color="inherit" /> : <DownloadIcon />}
                                disabled={disabledDownloadBtn && currentFile === file?.id}
                                title="Download"
                                size="small"
                                onClick={() => handleOpenFile(file.id, file.path.replace(/^.*[\\/]/, ''))}
                              />
                            )}
                            <Button
                              key={file.id}
                              startIcon={(disabledOpenBtn && (currentFile === file?.id)) ? <CircularProgress size={15} color="inherit" /> : <OpenInNewIcon />}
                              disabled={disabledOpenBtn && currentFile === file?.id}
                              title="Open"
                              size="small"
                              sx={{ margin: '0', width: '100%' }}
                              onClick={() => {
                                if (currentOpenFile) {
                                  currentOpenFile(file?.id);
                                } else {
                                  if (file?.proof_of_payment) {
                                    handleOpenFile(file.id, '', true);
                                  } else {
                                    handleOpenFile(file.id);
                                  }
                                }
                              }}
                            />
                            {hasDeleteButton && (
                              <Button
                                startIcon={(isDeleteLoading && (currentFile === file?.id)) ? <CircularProgress size={15} color="inherit" /> : <DeleteIcon />}
                                sx={{ ...(RedButton), margin: '0', width: '100%' }}
                                onClick={() => handleDeleteFile(file.id)}
                                disabled={!hasPermissions || (isDeleteLoading && currentFile === file?.id)}
                                title="Delete"
                                size="small"
                              />
                            )}
                          </Box>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: file?.uploaded_by ? 'space-between' : 'flex-end', margin: '0.5rem'
                        }}
                        >
                          {(file?.uploaded_by) ? (
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{
                                margin: '0!important'
                              }}
                            >
                              {`Uploaded by: ${file?.uploaded_by}`}
                            </Typography>
                          ) : <></>}
                          {(file?.updated_at || file?.created_at || file?.uploaded_at) ? (
                            <Typography
                              noWrap
                              variant="subtitle2"
                              sx={{
                                margin: '0!important'
                              }}
                            >
                              {`Uploaded at: ${moment(file?.updated_at || file?.created_at || file?.uploaded_at).format('YYYY-MM-DD hh:mm:ss')}`}
                            </Typography>
                          ) : <></>}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="subtitle1" bold noWrap sx={{ textAlignLast: 'center' }}>
                    No files uploaded yet.
                  </Typography>
                )}
              </Grid>

              {hasUploadButton && (
                <Grid item xs={12} sx={{ textAlignLast: 'center' }}>
                  <Upload
                    sx={{ marginTop: '1rem' }}
                    disabled={!hasPermissions || isAttachLoading}
                    handleUpload={uploadFile}
                    title="Upload New File"
                    multiple
                  />
                </Grid>
              )}
            </Grid>
          )}
          {(dragAndDrop) && (
            <Box
              onDragLeave={(e) => {
                e.preventDefault();
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                setDragState(prev => ({
                  ...prev,
                  isFileDrag: true
                }));
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();

                uploadFile({ target: { files: e.dataTransfer.files } });
                setDragState(prev => ({
                  ...prev,
                  isFileDrag: false
                }));
              }}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                bottom: '10px',
                left: '10px',
                backgroundColor: 'rgba(0, 52, 103, 0.4)',
                boxShadow: '0 0 20px 9px #fff inset',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                opacity: 0,
                zIndex: '-1',

                ...(dragState.isFileDrag && {
                  opacity: 1,
                  zIndex: '999'
                }),

                transition: 'all .3s linear',
              }}
              className="dragAndDropAnimation"
            >
              <Typography variant="h4" sx={{ color: '#fff' }}>
                Drag file to this drop zone!
              </Typography>
            </Box>
          )}
        </Card>
      </Card>
    </Drawer>
  );
};

export default FileDrawer;
