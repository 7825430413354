import { StoreMallDirectory } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AirlinesIcon from '@mui/icons-material/Airlines';
import InsightsIcon from '@mui/icons-material/Insights';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';

import {
  AccountCheck,
  AccountClock,
  AccountGroup,
  AccountMultiple,
  AccountMultipleCheck,
  Airplane,
  Archive,
  ArchiveClock,
  Calculator,
  CalendarToday,
  ClipboardList,
  CreditCard,
  DeveloperBoard,
  FolderAccount,
  FolderMultiple,
  Fuel,
  HomeOutline,
  MapOutline,
  OrderBoolAscendingVariant,
  Tools,
  TruckOutline,
  Turbine,
  WrenchClock,
} from 'mdi-material-ui';
import BoxIcon from '../components/Icons/BoxIcon';
import BoxesIcon from '../components/Icons/BoxesIcon';
import { ALL_INVOICE_PERMISSIONS } from '../pages/Finance/InvoiceManagement/permissions';
import {
  dashboardExternalAccess, dashboardInternalAccess, myOrdersExternalAccess, myOrdersInternalAccess,
  storageAccess
} from '../pages/Logistics/constants';

export const navList = [
  {
    icon: HomeOutline,
    title: 'Main Deck',
    permissions: 'maindeck_access',
    path: '/',
  },
  {
    icon: Turbine,
    title: 'Aircraft',
    permissions: ['aircrafts_access', 'aircraft_profile'],
    children: [
      {
        icon: ClipboardList,
        title: 'Profiles',
        permissions: 'aircraft_profile',
        path: '/aircraft/profiles',
      },
      {
        icon: ClipboardList,
        title: 'Aircraft Types',
        permissions: 'aircrafts_access',
        path: '/aircraft/types',
      },
    ],
  },
  {
    icon: Calculator,
    title: 'Quotations',
    permissions: 'fleet_access',
    path: '/quotations',
  },
  {
    icon: Airplane,
    title: 'Flights',
    permissions: 'flights_access',
    path: '/flights',
  },
  {
    icon: Fuel,
    title: 'Fuel',
    permissions: 'fuel_access',
    children: [
      {
        icon: CreditCard,
        title: 'Fuel Uplifts',
        permissions: 'fuel_access',
        path: '/fuel/uplift',
      },
      {
        icon: CreditCard,
        title: 'Fuel Payments',
        permissions: 'fuel_access',
        path: '/fuel/payments',
      },
      {
        icon: CreditCard,
        title: 'Fuel Price',
        permissions: 'fuel_access',
        path: '/fuel/price',
      },
      {
        icon: CreditCard,
        title: 'Fuel Balance',
        path: '/fuel/balance',
      },
    ],
  },
  {
    icon: AccountClock,
    title: 'Crew Roster',
    permissions: 'roster_access',
    children: [
      {
        icon: AccountCheck,
        title: 'SAAB Roster',
        permissions: 'roster_saab_access',
        path: '/roster/saab',
      },
      {
        icon: AccountCheck,
        title: 'ATR Roster',
        permissions: 'roster_atr_access',
        path: '/roster/atr',
      },
      {
        icon: AccountCheck,
        title: 'TECHNICIANS Roster',
        permissions: 'roster_tech_access',
        path: '/roster/technicians',
      },
      {
        icon: AccountCheck,
        title: 'OPS Roster',
        permissions: 'roster_ops_access',
        path: '/roster/ops',
      },
      {
        icon: ArchiveClock,
        title: 'Duty | Flight time report',
        permissions: 'flight_report_access',
        path: '/roster/flighttime',
      },
    ],
  },
  {
    icon: FolderMultiple,
    title: 'Directories',
    permissions: ['map_access', 'invoice_request_access', 'airport_access', 'services_supplier_access', 'company_access', 'caa_access', 'crew_access', 'ods_access', 'users_documents'],
    children: [
      {
        icon: FolderAccount,
        title: 'Airports',
        permissions: 'airport_access',
        path: '/directories/airport',
      },
      {
        icon: FolderAccount,
        title: 'Crew',
        permissions: 'crew_access',
        path: '/directories/crew',
      },
      {
        icon: FolderAccount,
        title: 'Suppliers',
        permissions: 'services_supplier_access',
        path: '/directories/supplier',
      },
      {
        icon: FolderAccount,
        title: 'Clients',
        path: '/directories/company',
        permissions: 'company_access',
      },
      {
        icon: FolderAccount,
        title: 'ACFT Operator',
        path: '/directories/operator',
        permissions: 'operator_access',
      },
      {
        icon: FolderAccount,
        title: 'CAA',
        path: '/directories/caa',
        permissions: 'caa_access',
      },
      {
        icon: FolderAccount,
        title: 'ODS',
        permissions: 'ods_access',
        path: '/directories/ods/1',
      },
      {
        icon: FolderAccount,
        title: 'Documents & Invoices',
        permissions: ['users_documents', 'invoice_request_access'],
        path: '/directories/users-documents-and-invoices',
      },
      {
        icon: FolderAccount,
        title: 'Invoices',
        permissions: ['invoice_access', 'invoice_edit', 'invoice_su'],
        path: '/directories/invoices',
      },
      {
        icon: MapOutline,
        title: 'Maps',
        permissions: ['map_access'],
        path: '/directories/map',
      },
      {
        icon: ContentPasteIcon,
        title: 'IT Planning',
        permissions: ['plannings_write', 'plannings_read'],
        path: '/directories/it-planning',
      },
    ],
  },
  {
    icon: WrenchClock,
    title: 'Maintenance',
    permissions: ['maintenance_access', 'atr_maintenance_access', 'wo_access', 'aircrafts_access', 'utilization_report', 'aircraft_body_return_to_storage', 'atr_maintenance_delete_access'],
    children: [// Archive
      {
        icon: DeveloperBoard,
        title: 'ACFT Mnt. Development',
        permissions: ['maintenance_access', 'atr_maintenance_access'],
        path: '/maintenance/development/aircraft/1',
      },
      {
        icon: Tools,
        title: 'ACFT Mnt. Current',
        permissions: ['maintenance_access', 'atr_maintenance_access'],
        path: '/maintenance/current/aircraft/1',
      },
      {
        icon: Archive,
        title: 'ACFT Mnt. Archive',
        permissions: ['maintenance_access', 'atr_maintenance_access'],
        path: '/maintenance/archive/aircraft/1',
      },
      {
        icon: OrderBoolAscendingVariant,
        title: 'Work Order',
        permissions: 'wo_access',
        path: '/maintenance/work-order/1'
      },
      {
        icon: Airplane,
        title: 'Aircraft',
        permissions: ['aircrafts_access', 'atr_maintenance_delete_access'],
        path: '/maintenance-aircraft'
      },
      {
        icon: SummarizeIcon,
        title: 'Utilization report',
        permissions: 'utilization_report',
        path: '/maintenance-utilization-report'
      },
      {
        icon: AirlinesIcon,
        title: 'Aircraft Body',
        permissions: ['wo_creator', 'wo_superuser', 'admin', 'aircraft_body_return_to_storage'],
        path: '/maintenance-aircraft-body'
      }
      // {
      //   icon: FolderAccount,
      //   title: 'Archive of ATR Maintenance',
      //   path: '/maintenance/archive/1',
      // },
    ]
  },
  {
    icon: TruckOutline,
    title: 'Logistics',
    permissions: ['logistics_order_read_aog', 'logistics_order_read', 'logistics_order_creator', 'logist', 'logistics_storage_master', 'logistics_order_approve', 'logistics_storage_read', 'logistics_storage_write', 'logistics_suppliers_read', 'logistics_suppliers_write', 'logistics_aircraft_reservation', 'internal_logistics_order_creator', 'internal_logistics_order_read', 'internal_logist', 'internal_logistics_order_approve', 'internal_logistics_storage_master', 'internal_logistics_order_read_aog', 'internal_logistics_storage_write'],
    children: [
      {
        icon: BoxIcon,
        title: 'My Orders',
        permissions: [...myOrdersExternalAccess, ...myOrdersInternalAccess],
        path: '/logistics/my-orders/orders',
      },
      // {
      //   icon: BoxIcon,
      //   title: 'My Internal Orders',
      //   permissions: ['internal_logistics_order_creator', 'internal_logistics_order_read', 'internal_logistics_order_read_aog'],
      //   path: '/logistics/my-orders/delivery',
      // },
      {
        icon: BoxesIcon,
        title: 'Dashboard',
        permissions: [...dashboardExternalAccess, ...dashboardInternalAccess],
        path: '/logistics/dashboard/orders',
      },
      // {
      //   icon: BoxesIcon,
      //   title: 'Internal Dashboard',
      //   permissions: ['internal_logist', 'internal_logistics_order_read', 'internal_logistics_order_approve', 'internal_logistics_order_read_aog'],
      //   path: '/logistics/dashboard/delivery',
      // },
      {
        icon: WarehouseIcon,
        title: 'Storage',
        permissions: storageAccess,
        path: '/logistics/orders-storage',
      },
      {
        icon: StoreMallDirectory,
        title: 'Vendors',
        permissions: ['logistics_suppliers_read', 'logistics_suppliers_write'],
        path: '/logistics/vendors',
      }
    ]
  },
  { // Finance
    icon: AccountBalanceWalletRoundedIcon,
    title: 'Finance',
    permissions: [
      'finance_income_reader',
      'finance_income_writer',
      'finance_generate_invoice_read',
      'finance_generate_invoice_write',
      'payment_data_export',
      'payment_data_admin',
      'payment_data_access',
      'bank_access',
      'finance_transactions_read',
      'finance_transactions_write',
      'finance_statistics_read',
      'finance_transactions_financiers',
      'finance_transactions_tax',
      'logistic_statistic_access',
      ...ALL_INVOICE_PERMISSIONS
    ],
    children: [
      {
        icon: StackedLineChartIcon,
        title: 'Income',
        permissions: ['finance_income_reader', 'finance_income_writer'],
        path: '/finance/income',
      },
      {
        icon: DashboardIcon,
        title: 'Invoice Management',
        permissions: ALL_INVOICE_PERMISSIONS,
        path: '/finance/invoice_management'
      },
      {
        icon: ReceiptIcon,
        title: 'Generate Invoices',
        permissions: ['finance_generate_invoice_read', 'finance_generate_invoice_write'],
        path: '/finance/generate_invoice',
      },
      {
        icon: AccountBalanceWalletIcon,
        title: 'Salaries',
        permissions: ['payment_data_export', 'payment_data_admin', 'payment_data_access'],
        path: '/directories/payment-data',
      },
      {
        icon: CreditCard,
        title: 'Transactions',
        permissions: ['finance_transactions_read', 'finance_transactions_write', 'finance_transactions_financiers', 'finance_transactions_tax'],
        path: '/finance/transactions',
      },
      {
        icon: AccountBalanceIcon,
        title: 'Banks',
        permissions: 'bank_access',
        path: '/finance/banks',
      },
      {
        icon: FlagCircleIcon,
        title: 'Personal Reports',
        permissions: 'invoice_request_payment',
        path: '/finance/personal-reports',
      },
      {
        icon: InsightsIcon,
        title: 'Statistics',
        permissions: 'finance_statistics_read',
        path: '/finance/statistics',
      },
      {
        icon: InsightsIcon,
        title: 'Logistic Statistics',
        permissions: ['logistic_statistic_access'],
        path: '/finance/logistic-statistics',
      },
    ]
  },
  {
    // icon: BarChartIcon,
    icon: InsightsIcon,
    title: 'Statistics',
    permissions: ['flights_statistics_access', 'flights_comm_statistics_access'],
    children: [
      {
        // icon: BarChartIcon,
        title: 'By Company',
        permissions: 'flights_comm_statistics_access',
        path: '/statistics/by-company',
      },
      {
        // icon: BarChartIcon,
        title: 'By Departure',
        permissions: 'flights_statistics_access',
        path: '/statistics/by-departure',
      },
      {
        // icon: BarChartIcon,
        title: 'By Destination',
        permissions: 'flights_statistics_access',
        path: '/statistics/by-destination',
      },
      {
        // icon: BarChartIcon,
        title: 'By Sector',
        permissions: 'flights_statistics_access',
        path: '/statistics/by-sector',
      },
      {
        // icon: BarChartIcon,
        title: 'By Profit',
        permissions: 'flights_comm_statistics_access',
        path: '/statistics/by-profit',
      },
    ]
  },
  {
    icon: CalendarToday,
    title: 'Training Module',
    permissions: ['training_module_read', 'training_module_write', 'training_report_read', 'training_report_write'],
    children: [
      {
        icon: ClipboardList,
        title: 'Main Page',
        permissions: 'training_module_read',
        path: '/training-module',
      },
      {
        icon: ClipboardList,
        title: 'Flight Crew',
        permissions: ['training_module_read', 'training_module_write'],
        path: '/training-module/flight-crew',
      },
      {
        icon: ClipboardList,
        title: 'Training Programs',
        permissions: ['training_module_read', 'training_module_write'],
        path: '/training-module/training-programs',
      },
      {
        icon: ClipboardList,
        title: 'Training Personnel',
        permissions: ['training_module_read', 'training_module_write'],
        path: '/training-module/training-personnel',
      },
      {
        icon: ClipboardList,
        title: 'Training Schedule',
        permissions: ['training_module_read', 'training_module_write'],
        path: '/training-module/training-schedule',
      },
      {
        icon: SummarizeIcon,
        title: 'Training Reports',
        permissions: ['training_report_read', 'training_report_write'],
        path: '/training-module/training-reports',
      },
    ],
  },
  {
    icon: AccountGroup,
    title: 'Users',
    permissions: ['users_access', 'admin'],
    children: [
      {
        icon: AccountMultiple,
        title: 'Users',
        permissions: 'users_access',
        path: '/manage-users',
      },
      {
        icon: FolderAccount,
        title: 'Access Groups',
        permissions: 'admin',
        path: '/manage-users-groups',
      },
      {
        icon: AccountMultipleCheck,
        title: 'Users Positions',
        permissions: 'admin',
        path: '/manage-users-positions',
      }
    ]
  }
];

export const HISTORY_GO_BACK = -1;
export const FLIGHT_CARGO = 1;
export const FLIGHT_TECH = 3;
export const FERRY = 'FERRY';
export const SLOT = 3122;

export const HOME_PAGES = {
  1: '/quotations',
  2: '/flights',
  3: '/flights/statbycomp',
  4: '/directories/airport',
  5: '/directories/company',
  6: '/directories/supplier',
  7: '/fuel/uplift',
  8: '/fuel/payments',
  9: '/fuel/price',
  10: '/fuel/balance',
  11: '/maintenance',
  12: '/maintenanceExpenses',
  13: '/maintenanceTasks',
  14: '/directories/caa',
  15: '/directories/ods/1',
  16: '/roster/saab',
  17: '/roster/atr',
  18: '/',
  19: '/cashbook',
  20: '/workorder',
  21: '/my-profile',
  22: '/maindeck/schedule'
};

export const HOME_PAGES_LABELS = {
  1: 'Quotations',
  2: 'Flights',
  3: 'Flight Results',
  4: 'Airports',
  5: 'Clients',
  6: 'Services Suppliers',
  7: 'Fuel Uplifts',
  8: 'Fuel Payments',
  9: 'Fuel Price',
  10: 'Fuel Balance',
  11: 'Maintenance',
  12: 'Maintenance Expenses',
  13: 'Maintenance Tasks',
  14: 'CAA',
  15: 'ODS',
  16: 'Crew Roster SAAB',
  17: 'Crew Roster ATR',
  18: 'MAIN DECK',
  19: 'Cashbook',
  20: 'WO Index',
  21: 'My profile',
  22: 'Flight Schedule'
};
