import {
  Box,
  Card,
  Divider,
  Grid, InputAdornment, Tab, Tabs, TextField,
  Typography,
} from '@mui/material';
import { Rename } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import Upload from '../../../../../../components/Upload';
import Autocomplete from '../../../../../../form/components/Autocomplete/Autocomplete';
import DatePicker from '../../../../../../form/components/DatePicker';
import FormGroup from '../../../../../../form/components/FormGroup';
import { useGetInternalPaymentRequestsQuery } from '../../../../../../store/slices/internalLogisticsSlice';
import { useGetPaymentRequestsQuery } from '../../../../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../../../../utils/hooks/usePermissions';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { headerBtn } from '../../../../../Invoices/style';
import { externalOrdersType } from '../../../../constants';
import { Filters, GreenButton, RedButton } from '../../../../style';
import { getOrderType } from '../../../../utils';
import InvoiceCard from '../approved/invoices/InvoiceCard';
import InvoiceTableHeader from '../approved/invoices/InvoiceTableHeader';
import InvoicesForm from '../approved/invoices/InvoicesForm';
import { file, fileName, label } from '../approved/invoices/style';
import ExchangeDueDate from '../exchangeDueDate/ExchangeDueDate';
import Certificates from './components/certificates/Certificates';
import StorageForm from './components/storageForm/StorageForm';
import { useOnTheWayForm } from './useOnTheWayForm';

const OnTheWayForm = ({ onClose, refetch }) => {
  const { order: currentOrder, currencies } = useSelector((state) => state.logistics);
  const { order: internalOrder } = useSelector((state) => state.internalLogistics);
  const location = useLocation();
  const dashboardType = location.pathname.split('/')[3];
  const [disabledSerialNumber, setDisabledSerialNumber] = useState(false);
  const order = dashboardType === 'orders' ? currentOrder : internalOrder;
  const [activeTab, setActiveTab] = useState('storage');
  const [nameFile, setNameFile] = useState('');
  const { data: currentPartData } = useGetPaymentRequestsQuery({ orderId: order?.id, payment_type: activeTab }, {
    skip: (dashboardType !== 'orders') || !['part', 'delivery'].includes(activeTab)
  });
  const internalPaymentType = order?.part_type?.includes('_out') || order?.part_type?.includes('_in') ? 'overhaul' : order?.part_type?.toLowerCase();
  const { data: internalData } = useGetInternalPaymentRequestsQuery({ orderId: order?.id, payment_type: (activeTab === 'part') ? internalPaymentType : 'delivery' }, {
    refetchOnMountOrArgChange: true,
    skip: dashboardType === 'orders'
  });

  const deliverData = dashboardType === 'orders' ? currentPartData : internalData;
  const partData = dashboardType === 'orders' ? currentPartData : internalData;

  // Access
  const userAccess = usePermissions('logistics_storage_master');
  const internalUserAccess = usePermissions('internal_logistics_storage_master');
  const internalLogist = usePermissions('internal_logist');
  const logistAccess = usePermissions('logist');

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const {
    ttmFormMethods,
    formMethods,
    handleSubmit,
    onSubmitTTM,
    handleSubmitTTM,
    onSubmit,
    isLoading,
    onSaveEstimatedDate
  } = useOnTheWayForm({
    order, onClose, refetch, dashboardType
  });

  const { errors } = formMethods.formState;

  const uploadDeliveryCertificates = (e) => {
    if (e.target.files[0]) {
      formMethods.setValue('certificate', e.target.files[0]);
    }
  };
  useEffect(() => {
    if (logistAccess || internalLogist) ttmFormMethods.setValue('ttm_code', order?.ttm_code || order?.ttn_code);
  }, [logistAccess, internalLogist, order]);

  useEffect(() => {
    if (order) {
      if (dashboardType === 'delivery') {
        const quantity = Array.isArray(order?.quantity);
        let quantitySum = 0;

        if (quantity) {
          order?.quantity?.forEach(el => quantitySum += +el?.count);
        }

        // formMethods.setValue('serial_number', (quantitySum > 1) ? 'N/A' : '');
        setDisabledSerialNumber((quantitySum > 1));
      } else {
        setDisabledSerialNumber((order?.part_count > 1));
      }
    }

    if (order?.delivery_due_date) {
      formMethods.setValue('items[0].expected_day_delivery', order?.delivery_due_date?.split(' ')?.[0] || order?.delivery_due_date);
    } else if (order?.prices?.[0]?.estimated_date) {
      formMethods.setValue('items[0].expected_day_delivery', order?.prices?.[0]?.estimated_date);
    }

    formMethods.setValue('items[0].type', order?.part_type);
  }, [order]);

  const changeTypes = ['Overhaul_out', 'Repair_out', 'Repair_in', 'Overhaul_in', 'Exchange'];
  const isRemovePartsInvoicesRequests = (order?.part_type === 'delivery') || (order?.part_type === 'Other') || (order?.part_type === 'Return') || (order?.prices?.[0]?.transaction_type === 'exchange');
  const isChangeNamePartsInvoicesRequests = changeTypes.includes(order?.part_type) ? `${changeTypes.find(item => item === order?.part_type)} Requests` : 'Parts Invoices Requests';
  const isToStorage = (
    order?.address_to?.includes('Fleet Air International')
    || order?.address_to?.toLowerCase()?.includes('hof')
    || order?.address_to?.toLowerCase()?.includes('budapest'));
  if (!userAccess && !internalUserAccess) return <Typography sx={{ margin: '20px 0', textAlign: 'center' }}>You don&apos;t have access.</Typography>;

  return (
    <>
      <Card>
        <Box>
          <Tabs
            sx={{ mt: 0, pt: 0 }}
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            centered
            aria-label="basic tabs example"
          >
            {((dashboardType === 'orders') ? userAccess : internalUserAccess) && (
            <Tab
              label={(
                (!isToStorage && (order?.part_type === 'delivery')) || ['Exchange', 'Repair_out', 'Overhaul_out', 'Repair_in', 'Overhaul_in', 'Other'].includes(order?.part_type)) ? 'Submit' : 'Storage'}
              value="storage"
            />
            )}
            {((order?.prices?.[0]?.transaction_type === 'exchange') && (dashboardType === 'orders')) && <Tab label="Exchange Due Date" value="exchange" />}
            {((dashboardType === 'orders') ? logistAccess : internalLogist) && <Tab label="Tracking Number" value="ttn" />}
            {(!isRemovePartsInvoicesRequests && ((dashboardType === 'orders') ? logistAccess : internalLogist)) && <Tab label={getOrderType(isChangeNamePartsInvoicesRequests)} value="part" />}
            {((dashboardType === 'orders') ? logistAccess : internalLogist) && <Tab label="Delivery Invoices Requests" value="delivery" />}
          </Tabs>
        </Box>
      </Card>
      {((order?.prices?.[0]?.transaction_type === 'exchange') && (activeTab === 'exchange')) && (
        <ExchangeDueDate orderId={order?.id} refetchOrder={refetch} exchangeDueDate={order?.exchange_due_date} />
      )}
      {(logistAccess || internalLogist) && (
      <Card sx={{ padding: '0rem 1rem 1rem 1rem' }}>
        {
          (activeTab === 'ttn') && (
            <Box>
              <form onSubmit={handleSubmitTTM(onSubmitTTM)}>
                <Box sx={{ ...headerBtn }}>
                  <Box>
                    <Typography variant="h5" noWrap>
                      You can change Tracking Number.
                    </Typography>
                  </Box>
                </Box>

                <Grid container item xs={12}>
                  <Controller
                    name="ttm_code"
                    control={ttmFormMethods.control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Enter Tracking Number"
                        onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                        error={!!ttmFormMethods.formState.errors.ttm_code}
                        helperText={ttmFormMethods.formState.errors.ttm_code ? ttmFormMethods.formState.errors.ttm_code.message : ''}
                        variant="outlined"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />

                  {(externalOrdersType.includes(order?.part_type) && (order?.prices?.[0]?.transaction_type === 'outright')) && (
                    <Grid container spacing={2} sx={{ mt: '1rem' }}>
                      <Grid item xs={4}>
                        <FormGroup label="Estimated Delivery Date">
                          <DatePicker
                            placeholderText="YYYY-MM-DD"
                            dateFormat="yyyy-MM-dd"
                            showMonthDropdown
                            disabled
                            showYearDropdown
                            value={validateDatePickerValue(order?.prices?.[0]?.estimated_date)}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Estimated Delivery Price">
                          <TextField
                            size="small"
                            type="number"
                            disabled
                            value={order?.prices?.[0]?.estimated_delivery_price}
                            placeholder="Estimated Delivery Price"
                            sx={{ width: '100%' }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(order?.prices?.[0]?.estimated_delivery_price && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Estimated Delivery Currency">
                          <Autocomplete
                            value={order?.prices?.[0]?.estimated_delivery_currency_id ? currencies?.filter(cur => (+cur?.value === +order?.prices?.[0]?.estimated_delivery_currency_id))?.[0] : null}
                            options={currencies}
                            disabled
                            size="small"
                            placeholder="Please select a Estimated Delivery Currency"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Button type="submit" sx={{ mt: 5 }}>Save</Button>
              </form>
            </Box>
          )
        }

        {(activeTab === 'part') && (
        <Box p={3}>
          {partData && Object.values(partData)?.length ? (
            <>
              <InvoiceTableHeader />
              {Object.values(partData)?.map((element) => (
                <InvoiceCard key={element.id} invoice={element} />
              ))}
              <Divider sx={{ pt: '10px' }} />
            </>
          ) : (
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
            }}
            >
              <Typography variant="h5" noWrap>
                No related invoices
              </Typography>
              <Typography variant="subtitle2" noWrap>
                Please add new invoice.
              </Typography>
            </Box>
          )}
          <InvoicesForm
            order={order}
            variant={
              ['Overhaul_out', 'Repair_out', 'Repair_in', 'Overhaul_in'].includes(order?.part_type) ? 'overhaul'
                : (order?.part_type === 'Exchange') ? 'exchange' : 'part'
            }
            dashboardType={dashboardType}
          />
        </Box>
        )}

        {(activeTab === 'delivery') && (
        <Box p={3}>
          {deliverData && Object.values(deliverData)?.length ? (
            <>
              <InvoiceTableHeader />
              {Object.values(deliverData)?.map((element) => (
                <InvoiceCard key={element.id} invoice={element} />
              ))}
              <Divider sx={{ pt: '10px' }} />
            </>
          ) : (
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
            }}
            >
              <Typography variant="h5" noWrap>
                No related invoices
              </Typography>
              <Typography variant="subtitle2" noWrap>
                Please add new invoice.
              </Typography>
            </Box>
          )}
          <InvoicesForm order={order} variant="delivery" dashboardType={dashboardType} />
        </Box>
        )}
      </Card>
      )}
      {(
        ((activeTab === 'storage') && (dashboardType === 'orders'))
        || (((activeTab === 'storage') && (dashboardType !== 'orders') && ['Exchange', 'Repair_out', 'Overhaul_out', 'Repair_in', 'Overhaul_in', 'Other', 'delivery'].includes(order?.part_type) && isToStorage))) && (

        <FormProvider {...formMethods}>
          <form>
            <Card sx={{ padding: '0rem 1rem 1rem 1rem' }}>
              {(((dashboardType === 'delivery') && Array.isArray(order?.quantity)) || (dashboardType === 'orders')) && (
              <Box sx={{ ...headerBtn }}>
                <Box>
                  <Typography variant="h5" noWrap>
                    Enter details
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    Enter storage, serial numbers and storage zone.
                  </Typography>
                </Box>
              </Box>
              )}
              {Array.isArray(order?.parts) ? (
                <StorageForm
                  formMethods={formMethods}
                  errors={errors}
                  items={((dashboardType === 'delivery') && Array.isArray(order?.quantity)) ? order?.parts : []}
                  submit={(
                    <Button
                      sx={{ mt: 2, ...(GreenButton) }}
                      variant="contained"
                      size="large"
                      disabled={!isLoading}
                      onClick={handleSubmit(onSubmit)}
                      title="Submit"
                    />
                  )}
                  onClose={onClose}
                  isLoading={isLoading}
                  quantity={order?.quantity}
                />
              ) : (
                <Grid container spacing={2} sx={{ mt: '20px' }}>
                  <Grid item xs={12}>
                    <Controller
                      name="items[0].storage_number"
                      control={formMethods.control}
                      rules={{ required: 'Storage number is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Storage Number"
                          error={!!errors.items?.[0].storage_number}
                          helperText={errors.items?.[0]?.storage_number ? errors.items?.[0]?.storage_number?.message : ''}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: '15px' }}>
                    <Controller
                      name="items[0].serial_number"
                      control={formMethods.control}
                      rules={{ required: 'Serial number is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Serial Number"
                          error={!!errors?.items?.[0]?.serial_number}
                          helperText={errors?.items?.[0]?.serial_number ? errors?.items?.[0]?.serial_number?.message : ''}
                          variant="outlined"
                          disabled={disabledSerialNumber}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: '15px' }}>
                    <Controller
                      name="items[0].storage_zone"
                      control={formMethods.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Storage Zone"
                          error={!!errors?.items?.[0]?.storage_zone}
                          helperText={errors?.items?.[0]?.storage_zone ? errors?.items?.[0]?.storage_zone?.message : ''}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: '15px' }}>
                    <FormGroup
                      label="Estimated Delivery Date"
                    >
                      <Controller
                        name="items[0].expected_day_delivery"
                        control={formMethods?.control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            placeholderText="YYYY-MM-DD"
                            dateFormat="yyyy-MM-dd"
                            showMonthDropdown
                            variant="outlined"
                            fullWidth
                            showYearDropdown
                            onChange={(e) => {
                              field.onChange(e);
                              setDateValue(e, formMethods?.setValue, 'items[0].expected_day_delivery', 'YYYY-MM-DD');
                            }}
                            value={validateDatePickerValue(field.value)}
                            sx={{
                              '& .MuiInputBase-input': {
                                padding: '16.5px 14px'
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: '15px' }}>
                    {!['Exchange', 'Repair_out', 'Overhaul_out', 'Repair_in', 'Overhaul_in', 'Other', 'delivery'].includes(order?.part_type)
                      ? <Certificates order={order} dashboardType={dashboardType} uploadDeliveryCertificates={uploadDeliveryCertificates} />
                      : (
                        <Box sx={file}>
                          <FormGroup sx={label} required label="Certificate" hasError={!!errors.certificate}>
                            <Controller
                              name="certificate"
                              control={formMethods.control}
                              render={({ field }) => (
                                <Upload
                                  {...field}
                                  accept=".pdf"
                                  handleUpload={(e) => {
                                    if (!e.target.files[0]) return;
                                    if (!e.target.files[0]?.name?.includes('.pdf')) return;
                                    formMethods.setValue('certificate', e.target.files?.[0]);
                                    setNameFile(e.target.files[0].name);
                                  }}
                                  title="Upload file"
                                />
                              )}
                            />
                          </FormGroup>
                          <Typography sx={{
                            ...(fileName), overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap'
                          }}
                          >
                            {nameFile}
                          </Typography>
                        </Box>
                      )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                      <Button
                        sx={{ mt: 2, ...(RedButton) }}
                        variant="contained"
                        size="large"
                        title="Cancel"
                        onClick={onClose}
                      />
                      <Button
                        sx={{ mt: 2, ...(GreenButton) }}
                        variant="contained"
                        size="large"
                        disabled={!isLoading}
                        onClick={handleSubmit(onSubmit)}
                        title="Submit"
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Card>
          </form>
        </FormProvider>
      ) }
      {(
        !isToStorage && (['Exchange', 'Repair_out', 'Overhaul_out', 'Other', 'delivery', 'Return'].includes(order?.part_type) && (activeTab === 'storage'))) && (
        <>
          <Grid item xs={12}>
            <FormGroup
              label="Estimated Day Delivery"
            >
              <Controller
                name="items[0].expected_day_delivery"
                control={formMethods?.control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, formMethods?.setValue, 'items[0].expected_day_delivery', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <FormGroup*/}
          {/*    label="Estimated Due Date"*/}
          {/*    required*/}
          {/*    hasError={!!errors?.items?.[0]?.estimated_date}*/}
          {/*  >*/}
          {/*    <Controller*/}
          {/*      name="items[0].estimated_date"*/}
          {/*      control={formMethods?.control}*/}
          {/*      render={({ field }) => (*/}
          {/*        <DatePicker*/}
          {/*          {...field}*/}
          {/*          placeholderText="YYYY-MM-DD"*/}
          {/*          dateFormat="yyyy-MM-dd"*/}
          {/*          showMonthDropdown*/}
          {/*          showYearDropdown*/}
          {/*          onChange={(e) => {*/}
          {/*            field.onChange(e);*/}
          {/*            setDateValue(e, formMethods?.setValue, 'items[0].estimated_date', 'YYYY-MM-DD');*/}
          {/*          }}*/}
          {/*          value={validateDatePickerValue(field.value)}*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </FormGroup>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
              <Button
                sx={{ mt: 2, ...(RedButton) }}
                variant="contained"
                size="large"
                title="Cancel"
                onClick={onClose}
              />
              <Button
                sx={{ mt: 2, ...(GreenButton) }}
                variant="contained"
                size="large"
                disabled={!isLoading}
                onClick={handleSubmit(onSubmit)}
                title="Submit"
              />
            </Box>
          </Grid>
        </>
      )}
      {(
        !isToStorage && (['Repair_in', 'Overhaul_in'].includes(order?.part_type) && (activeTab === 'storage'))) && (
          <>
            <form>
              <Grid item xs={12}>
                <FormGroup
                  label="Estimated Delivery Date"
                  required
                >
                  <Controller
                    name="items[0].estimated_date"
                    control={formMethods?.control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, formMethods?.setValue, 'items[0].estimated_date', 'YYYY-MM-DD');
                        }}
                        value={validateDatePickerValue(field.value)}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                  <Button
                    sx={{ mt: 2, ...(GreenButton) }}
                    variant="contained"
                    size="large"
                    disabled={!isLoading}
                    onClick={handleSubmit(onSaveEstimatedDate)}
                    title="Save"
                  />
                </Box>
              </Grid>
            </form>

            <Grid item xs={12}>
              <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                <Button
                  sx={{ mt: 2, ...(RedButton) }}
                  variant="contained"
                  size="large"
                  title="Cancel"
                  onClick={onClose}
                />
                <Button
                  sx={{ mt: 2, ...(GreenButton) }}
                  variant="contained"
                  size="large"
                  disabled={!isLoading}
                  onClick={handleSubmit(onSubmit)}
                  title="Submit"
                />
              </Box>
            </Grid>
          </>
      )}
    </>

  );
};

export default OnTheWayForm;
