import {
  array,
  boolean,
  object,
  string
} from 'yup';

export const defaultValues = {
  rows: [
    {
      comments: '',
      submitted_at: false,
    }
  ]
};

export const schema = object().shape({
  rows: array().of(object({}).shape({
    comments: string()
      .nullable(),
    submitted_at: boolean()
      .nullable(),
  }))
});
