import {
  Box, CardContent, Divider, Typography
} from '@mui/material';
import React from 'react';
import { headerBtn, headerBtnS } from '../../../../Invoices/style';
import { Item } from '../../../../Logistics/style';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import toast from 'react-hot-toast';
import { maxWidth1500px } from '../../../../../constans/mediaQuery';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';
import { StatusText } from '../../../../Logistics/OrdersDashboard/styles';
import { CardAction } from '../../styles';

const PersonalDashboardInvoiceCard = ({
  props, invoice, onOpen, permissions
}) => {
  const formattedInvoice = props.formatPersonalInvoice(invoice);
  const hasPermission = usePermissions(permissions);

  const handleCheckPermission = (callback) => {
    if (hasPermission) {
      callback();
    } else {
      toast.error('You do not have access!', {
        duration: 3000
      });
    }
  };

  return (
    <Item onClick={() => handleCheckPermission(() => onOpen(formattedInvoice.id))}>
      <CardContent sx={{ position: 'relative' }}>
        <Box sx={{ ...headerBtn, ...(maxWidth1500px() && headerBtnS) }}>
          <Box>
            <Typography noWrap variant="subtitle1" sx={StatusText}>
              {formattedInvoice.number}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box>
          <Typography noWrap variant="body1">
            <b>Amount:</b>
            {' '}
            {formattedInvoice.amount}
          </Typography>
          <Typography noWrap variant="body1">
            <b>Sender:</b>
            {' '}
            {formattedInvoice.sender}
          </Typography>
          {formattedInvoice.transaction_id && (
            <Typography noWrap variant="body1">
              <b>Transaction: №</b>
              {' '}
              {formattedInvoice.transaction_id}
            </Typography>
          )}
        </Box>

        <Divider />

        <Box
          sx={CardAction}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCheckPermission(() => props.handleDownloadInvoice({ id: invoice.id, file_name: invoice.file_name }));
          }}
        >
          <DownloadIcon />
          Download Report
        </Box>
      </CardContent>
    </Item>
  );
};

export default PersonalDashboardInvoiceCard;
