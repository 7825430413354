
import React from 'react';
import { HighPriorityIcon, LowPriorityIcon, MediumPriorityIcon } from '../../components/Icons';

export const PLANNING_PRIORITIES = [
  // eslint-disable-next-line react/jsx-filename-extension
  { value: 2, label: 'High', icon: <HighPriorityIcon /> },
  { value: 1, label: 'Medium', icon: <MediumPriorityIcon /> },
  { value: 0, label: 'Low', icon: <LowPriorityIcon /> }
];
